<template>
  <div class="lead-modal">
    <Tabs v-model="activeTab">
      <Tab name="overview" title="Overview">
        <div class="lead-details" v-if="!loading">
          <div class="lead-details__content">
            <div class="lead-details__main">
              <div class="lead-details__fields">
                <h3 class="mb-6">Contact details</h3>
                <div class="field-item">
                  <label>
                    <BaseIcon name="user" size="12" />
                    Name
                  </label>
                  <InplaceEdit :value="localLead.name" @update="updateField('name', $event)">
                    <template #editor="{ value, update, save, cancel }">
                      <div class="field-item__editor">
                        <TextInput
                          type="text"
                          :modelValue="value"
                          @update:modelValue="update"
                          @blur="save"
                          @keydown.enter="save"
                          @keydown.esc="cancel"
                          ref="input"
                        />
                        <BaseIcon name="check-circle" size="18" @click="save" />
                        <BaseIcon name="xmark" size="18" @click="cancel" />
                      </div>
                    </template>
                  </InplaceEdit>
                </div>
                <div class="field-item">
                  <label>
                    <BaseIcon name="email" size="12" />
                    Email
                  </label>
                  <InplaceEdit
                    :value="localLead.email"
                    @update="updateField('email', $event)"
                    disabled
                  ></InplaceEdit>
                </div>
                <div class="field-item">
                  <label>
                    <BaseIcon name="phone" size="12" />
                    Phone
                  </label>
                  <InplaceEdit :value="localLead.phone" @update="updateField('phone', $event)">
                    <template #editor="{ value, update, save, cancel }">
                      <div class="field-item__editor">
                        <TextInput
                          type="number"
                          :modelValue="value"
                          @update:modelValue="update"
                          @blur="save"
                          @keydown.enter="save"
                          @keydown.esc="cancel"
                          ref="input"
                        />
                        <BaseIcon name="check-circle" size="18" @click="save" />
                        <BaseIcon name="xmark" size="18" @click="cancel" />
                      </div>
                    </template>
                  </InplaceEdit>
                </div>
                <div class="field-item">
                  <label>
                    <BaseIcon name="marker" size="18" />
                    Address
                  </label>
                  {{ formatAddress(localLead.address) }}
                </div>
                <div class="field-item">
                  <label>
                    <BaseIcon name="notes" size="12" />
                    Description
                  </label>
                  <InplaceEdit
                    :value="localLead.notes || ''"
                    @update="updateField('notes', $event)"
                    multiline
                  >
                    <template #editor="{ value, update, save, cancel }">
                      <div class="field-item__editor">
                        <WysiwygEditor :modelValue="value" @update:modelValue="update" />
                        <BaseIcon name="check-circle" size="18" @click="save" />
                        <BaseIcon name="xmark" size="18" @click="cancel" />
                      </div>
                    </template>
                  </InplaceEdit>
                </div>
              </div>
            </div>

            <div class="lead-details__activities">
              <h3 class="mb-6">Activity Feed</h3>
              <div class="activity-input">
                <div class="activity-input__field">
                  <TextAreaInput
                    v-model="newNote"
                    placeholder="Leave a note on the timeline..."
                    class="activity-input__field"
                  />
                  <Btn variant="secondary" @click="addNote" :disabled="!newNote.trim()">
                    Add Note
                  </Btn>
                </div>
              </div>

              <div class="activity-list">
                <div
                  v-for="activity in sortedActivities"
                  :key="activity.id"
                  class="activity-item"
                  :class="{ ['activity-item--' + activity.type]: activity.type }"
                >
                  <div class="activity-marker">
                    <div class="activity-dot"></div>
                    <div class="activity-line"></div>
                  </div>
                  <div class="activity-content">
                    <div class="activity-timestamp">{{ formatDate(activity.timestamp) }}</div>
                    <div class="activity-text">
                      <template v-if="activity.type === 'submission'">
                        <BaseIcon name="zap" size="14" />
                        Form submission - {{ activity.status }}
                      </template>
                      <template v-else-if="activity.type === 'created'">
                        <BaseIcon name="plus-circle" size="14" />
                        Lead created
                      </template>
                      <template v-else-if="activity.type === 'note'">
                        <BaseIcon name="notes" size="14" />
                        {{ activity.content }}
                      </template>
                      <template v-else-if="activity.type === 'lead_update'">
                        <BaseIcon name="edit" size="14" />
                        Updated lead fields
                      </template>
                      <template v-else-if="activity.type === 'status_change'">
                        <BaseIcon name="user" size="14" />
                        Status updated
                      </template>
                      <template v-else-if="activity.type === 'proposal'">
                        <BaseIcon name="file" size="14" />
                        {{ activity.content }}
                      </template>
                      <template v-else>
                        {{ activity.description }}
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoadingSpinner v-else />
      </Tab>
      <Tab name="proposals" title="Proposals">
        <LeadProposals :lead-id="leadId" />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
  import { defineComponent, onMounted, ref, computed, watch } from 'vue';
  import { useCrmStore } from '@/store/crmStore';
  import { useToastStore } from '@/store/toastStore';

  import { formatDate } from '@/utils/date';

  import Tabs from '@/components/partials/Tabs/Tabs.vue';
  import Tab from '@/components/partials/Tabs/Tab.vue';

  import LeadProposals from '@/components/crm/LeadProposals.vue';

  import LoadingSpinner from '@/components/partials/LoadingSpinner.vue';
  import InplaceEdit from '@/components/partials/InplaceEdit.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import TextAreaInput from '@/components/inputs/TextAreaInput.vue';
  import BaseIcon from '@shared/components/BaseIcon.vue';
  import Btn from '@/components/partials/Btn.vue';
  import WysiwygEditor from '@/components/inputs/WysiwygEditor.vue';

  export default defineComponent({
    name: 'LeadDetails',

    components: {
      LoadingSpinner,
      InplaceEdit,
      TextInput,
      TextAreaInput,
      BaseIcon,
      Btn,
      WysiwygEditor,
      Tabs,
      Tab,
      LeadProposals,
    },

    props: {
      leadId: {
        type: String,
        required: true,
      },
    },

    setup(props) {
      const loading = ref(true);
      const crmStore = useCrmStore();
      const toastStore = useToastStore();
      const activeTab = ref('overview');

      const formatAddress = (address) => {
        if (!address) return '';
        const { street, houseNumber, postalCode, city, country } = address;
        return `${street || ''} ${houseNumber || ''}, ${postalCode || ''} ${city || ''}, ${country || ''}`
          .trim()
          .replace(/\s+/g, ' ');
      };

      const lead = computed(() => crmStore.currentLead);
      const localLead = ref(null);
      const hasChanges = computed(() => {
        if (!localLead.value || !lead.value) return false;
        return Object.keys(localLead.value).some(
          (key) => JSON.stringify(localLead.value[key]) !== JSON.stringify(lead.value[key])
        );
      });

      const activities = ref([]);
      watch(
        () => lead.value,
        (newLead) => {
          localLead.value = { ...newLead };
          const newActivities = [];

          // Add lead creation as an activity
          if (newLead?.created_at) {
            newActivities.push({
              id: 'lead-created',
              timestamp: new Date(newLead.created_at).getTime(),
              type: 'created',
            });
          }

          // Add submissions as activities
          newLead?.submissions?.forEach((submission) => {
            // Add submission creation activity
            if (submission.created_at) {
              newActivities.push({
                id: `${submission.id}-created`,
                timestamp: new Date(submission.created_at).getTime(),
                type: 'submission',
                status: 'created',
              });
            }
          });

          // Add regular activities (excluding form submissions)
          if (newLead?.activities) {
            const formattedActivities = newLead.activities
              .filter((activity) => activity.type !== 'form_submission') // Filter out form submissions
              .map((activity) => {
                if (activity.type === 'note') {
                  return {
                    id: activity.id,
                    timestamp: new Date(activity.created_at).getTime(),
                    type: activity.type,
                    content: activity.metadata.content,
                  };
                } else if (activity.type === 'lead_update') {
                  return {
                    id: activity.id,
                    timestamp: new Date(activity.created_at).getTime(),
                    type: activity.type,
                  };
                }
                return activity;
              });
            newActivities.push(...formattedActivities);
          }

          // Add proposal activities
          if (newLead?.proposal_activities) {
            const proposalActivities = newLead.proposal_activities.map((activity) => ({
              id: activity.id,
              timestamp: new Date(activity.created_at).getTime(),
              type: 'proposal',
              proposalId: activity.proposal_id,
              content: 'Proposal "' + activity.proposal_name + '" ' + activity.type,
            }));
            newActivities.push(...proposalActivities);
          }

          // Sort by timestamp ascending
          activities.value = newActivities.sort((a, b) => b.timestamp - a.timestamp);
        },
        { immediate: true, deep: true }
      );

      const sortedActivities = computed(() => activities.value);

      const updateField = async (field, value) => {
        localLead.value[field] = value;
        try {
          await crmStore.updateLead(props.leadId, { [field]: value });
          toastStore.addToast({
            message: 'Updated lead field',
            type: 'success',
            duration: 3000,
          });
        } catch (error) {
          console.error(`Failed to update ${field}:`, error);
        }
      };

      const newNote = ref('');

      const addNote = async () => {
        if (!newNote.value.trim()) return;

        const activity = {
          id: Date.now().toString(),
          timestamp: Date.now(),
          type: 'note',
          content: newNote.value.trim(),
        };

        try {
          await crmStore.addLeadActivity(props.leadId, activity);
          newNote.value = '';
          toastStore.addToast({
            message: 'Note added',
            type: 'success',
            duration: 3000,
          });
        } catch (error) {
          console.error('Failed to add note:', error);
        }
      };

      onMounted(async () => {
        try {
          await crmStore.loadLead(props.leadId);
        } catch (error) {
          console.error('Failed to load lead:', error);
        } finally {
          loading.value = false;
        }
      });

      return {
        activeTab,
        formatDate,
        formatAddress,
        loading,
        lead,
        localLead,
        sortedActivities,
        updateField,
        hasChanges,
        addNote,
        newNote,
      };
    },
  });
</script>

<style scoped lang="scss">
  .lead-details {
    &__content {
      display: flex;
      gap: 2rem;
      @include lg-down {
        flex-direction: column;
        gap: 1rem;
      }
    }

    &__main {
      max-width: 450px;
      width: 50%;
      @include lg-down {
        width: 100%;
      }
    }

    &__fields {
      .field-item {
        padding: 0.5rem 0;
        border-top: 1px solid var(--slate-200);
        &:last-child {
          border-bottom: none;
        }

        label {
          font-size: 0.875rem;
          color: var(--slate-800);
          margin-bottom: 0.5rem;
          font-weight: 600;
          display: flex;
          align-items: center;
          gap: 0.25rem;
        }
        &__editor {
          display: flex;
          align-items: center;
          .icon {
            margin-left: 0.25rem;
            color: var(--slate-500);
            @include hover {
              color: var(--slate-900);
            }
          }
          .wysiwyg-editor {
            flex-grow: 1;
          }
        }
      }
    }

    &__activities {
      flex: 1;

      .activity-input {
        margin-bottom: 2rem;
        padding: 1rem;
        background: var(--slate-50);
        border-radius: 0.5rem;

        &__field {
          padding: 0;
          position: relative;
          .btn {
            position: absolute;
            right: 0.5rem;
            bottom: 0.5rem;
          }
          :deep(textarea) {
            width: 100%;
            display: block;
            resize: none;
            height: 50px;
            padding-right: 80px;
          }
        }
      }

      .activity-list {
        position: relative;
        padding-left: 2rem;

        .activity-item {
          position: relative;
          padding: 1rem 0 1rem 0;
          &--note {
            --color: var(--amber-500);
          }
          &--submission {
            --color: var(--brand-500);
          }
          &--created {
            --color: var(--teal-500);
          }
          &--lead_update {
            --color: var(--orange-500);
          }
          &--status_change {
            --color: var(--indigo-500);
          }
          &--proposal {
            --color: var(--amber-500);
          }

          .activity-marker {
            position: absolute;
            left: -2rem;
            top: 0;
            bottom: 0;
            width: 2px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .activity-dot {
              width: 12px;
              height: 12px;
              background: var(--color);
              border-radius: 50%;
              // margin-top: 1.25rem;
              margin: 1rem 0 0.25rem 0;
            }

            .activity-line {
              flex: 1;
              width: 2px;
              background: var(--slate-200);
            }
          }

          .activity-content {
            background: white;
            border: 1px solid var(--slate-200);
            border-radius: 0.5rem;
            padding: 1rem;

            .activity-timestamp {
              font-size: 0.875rem;
              color: var(--slate-600);
              margin-bottom: 0.25rem;
            }
            .activity-text {
              color: var(--slate-900);
              display: flex;
              align-items: center;
              gap: 0.5rem;

              .icon {
                color: var(--slate-500);
              }
            }
          }
        }
      }
    }
  }
</style>
