<template>
  <div class="proposal-automation-config">
    <MessageElement class="mb-4" type="info">
      This automation creates a proposal based on the form fields selected.
    </MessageElement>
    <div class="form-section">
      <h4>Basic information</h4>
      <div class="form-row">
        <TextInput
          :id="inputs.title.id"
          :label="inputs.title.label"
          v-model="inputs.title.binding"
          :error="inputs.title.error"
          :rules="inputs.title.rules"
          :placeholder="inputs.title.placeholder"
          @validate="onValidate('title', $event)"
        />
        <NumberInput
          :id="inputs.days_valid_amount.id"
          :label="inputs.days_valid_amount.label"
          v-model="inputs.days_valid_amount.binding"
          :error="inputs.days_valid_amount.error"
          :rules="inputs.days_valid_amount.rules"
          @validate="onValidate('days_valid_amount', $event)"
        />
      </div>
    </div>
    <div class="form-section">
      <h4>Items</h4>
      <MessageElement variant="neutral" class="mb-4">
        <div>
          To automatically fill your proposal, pick one of the formulas or choices from your form.
        </div>
        <div class="mt-2 formula-picker">
          <button
            v-for="field in formulaElements"
            :key="field.id"
            @click.prevent="insertField(field, index)"
            class="formula-picker__button formula-picker__button--formula"
            :title="`${field.label} (${field.type})`"
          >
            <BaseIcon name="formula" />
            {{ field.label }}
          </button>
          <button
            v-for="field in choiceElements"
            :key="field.id"
            @click.prevent="insertField(field, index)"
            class="formula-picker__button formula-picker__button--choice"
            :title="`${field.label} (${field.type})`"
          >
            <BaseIcon name="check-square" />
            {{ field.label }}
          </button>
        </div>
      </MessageElement>
      <div class="items-list">
        <div class="items-list__list" :class="{ 'items-list--error': items.error }">
          <div
            v-for="(item, index) in items.binding"
            :key="item.id"
            class="item-row"
            :class="{ 'item-row--error': item.error }"
          >
            <div class="item-row__content">
              <TextInput
                :id="`item-${index}-description`"
                label="Description"
                v-model="item.description"
                :error="item.errorType === 'description' ? item.error : ''"
                :disabled="!!item.linked_element_id"
                info="This value is auto-filled based on the selected or calculated field"
              />
              <div class="item-row__element form-group" v-if="item.linked_element_id">
                <label>
                  Value
                  <span
                    class="form-group__info"
                    v-tippy="{
                      content:
                        'This value is auto-filled based on the selected or calculated field',
                    }"
                  >
                    <BaseIcon name="info" size="14" />
                  </span>
                </label>
                <span
                  class="element-tag"
                  :class="{
                    ['element-tag--' +
                    allElements.find((el) => el.id === item.linked_element_id)?.type]: true,
                  }"
                >
                  {{ allElements.find((el) => el.id === item.linked_element_id)?.props.label }}
                </span>
              </div>
              <TextInput
                v-else
                :id="`item-${index}-calculation_value`"
                label="Value"
                v-model="item.value"
                :error="item.errorType === 'value' ? item.error : ''"
                :rules="item.value.rules"
                @validate="onValidate('value', $event)"
                :disabled="!!item.linked_element_id"
              />
              <SelectInput
                :id="`item-${index}-vat_rate`"
                label="VAT Rate"
                v-model="item.vat_rate"
                :options="vatRates"
              />
            </div>
            <div class="item-row__actions">
              <Btn
                type="button"
                text="Clone"
                @click="cloneItem(index)"
                variant="plain"
                icon="clone"
                size="sm"
                class="mr-4"
              />
              <Btn
                type="button"
                text="Remove"
                @click="removeItem(index)"
                variant="plain"
                size="sm"
              />
            </div>
            <div v-if="item.error && !item.errorType" class="item-row__error">
              {{ item.error }}
            </div>
          </div>
        </div>
        <Btn variant="outlined" @click.prevent="addItem" icon="plus-circle" class="btn-add">
          Add item
        </Btn>
        <div class="items-list__error" v-if="items.error">{{ items.error }}</div>
      </div>
    </div>
    <div class="form-section">
      <h4>Settings</h4>
      <ToggleInput
        :id="inputs.auto_send_to_client.id"
        :label="inputs.auto_send_to_client.label"
        v-model="inputs.auto_send_to_client.binding"
        :error="inputs.auto_send_to_client.error"
        :rules="inputs.auto_send_to_client.rules"
        @validate="onValidate('auto_send_to_client', $event)"
      />
      <template v-if="inputs.auto_send_to_client.binding">
        <TextInput
          :label="inputs.proposal_mail_subject.label"
          v-model="inputs.proposal_mail_subject.binding"
          id="proposal_mail_subject"
        />
        <WysiwygEditor
          :label="inputs.proposal_mail_body.label"
          v-model="inputs.proposal_mail_body.binding"
          @blur="handleBlur('proposal_mail_body')"
          id="proposal_mail_body"
        />
      </template>
    </div>
  </div>
</template>

<script>
  import { ref, computed, onMounted, watch } from 'vue';
  import { v4 as uuidv4 } from 'uuid';
  import dayjs from 'dayjs';
  import TextInput from '@/components/inputs/TextInput.vue';
  import WysiwygEditor from '@/components/inputs/WysiwygEditor.vue';
  import ToggleInput from '@/components/inputs/ToggleInput.vue';
  import TextAreaInput from '@/components/inputs/TextAreaInput.vue';
  import NumberInput from '@/components/inputs/NumberInput.vue';
  import DateInput from '@/components/inputs/DateInput.vue';
  import CurrencyInput from '@/components/inputs/CurrencyInput.vue';
  import SelectInput from '@/components/inputs/SelectInput.vue';
  import Btn from '@/components/partials/Btn.vue';
  import { useValidation } from '@/composables/useValidation';
  import MessageElement from '@/components/partials/Message.vue';
  import LoadingSpinner from '@/components/partials/LoadingSpinner.vue';
  import BaseIcon from '@/components/partials/BaseIcon.vue';
  import { useAutomationStore } from '@/store/automationStore';
  import { useUserStore } from '@/store/userStore';

  export default {
    name: 'CreateEditProposal',
    components: {
      TextInput,
      TextAreaInput,
      WysiwygEditor,
      NumberInput,
      DateInput,
      CurrencyInput,
      SelectInput,
      ToggleInput,
      Btn,
      MessageElement,
      LoadingSpinner,
      BaseIcon,
    },
    props: {
      modelValue: {
        type: Object,
        required: false,
      },
    },

    setup(props, { emit }) {
      const automationStore = useAutomationStore();
      const items = ref({
        binding: [],
        error: '',
      });
      const currentAutomation = computed(() => automationStore.currentAutomation);
      const userStore = useUserStore();
      const organization = computed(() => userStore.currentOrganization);
      // Find formula elements from form details

      const allElements = computed(() => {
        if (!currentAutomation.value?.trigger_details?.formDetails?.pages) {
          return [];
        }
        return currentAutomation.value.trigger_details.formDetails.pages.flatMap(
          (page) => page.elements
        );
      });

      const formulaElements = computed(() => {
        if (!currentAutomation.value?.trigger_details?.formDetails?.pages) {
          return [];
        }

        const elements = [];
        currentAutomation.value.trigger_details.formDetails.pages.forEach((page) => {
          page.elements.forEach((element) => {
            if (element.type === 'formula') {
              elements.push({
                id: element.id,
                label: element.props.label,
                value: `{${element.id}}`,
              });
            }
          });
        });
        return elements;
      });

      const choiceElements = computed(() => {
        if (!currentAutomation.value?.trigger_details?.formDetails?.pages) {
          return [];
        }
        const elements = currentAutomation.value.trigger_details.formDetails.pages.flatMap(
          (page) => page.elements
        );
        return elements
          .filter((element) =>
            ['single-choice', 'multiple-choice', 'picture-choice', 'dropdown'].includes(
              element.type
            )
          )
          .map((element) => ({
            id: element.id,
            label: element.props.label,
            value: `{${element.id}}`,
            type: element.type,
          }));
      });

      const vatRates = [
        { value: 0, label: '0%' },
        { value: 9, label: '9%' },
        { value: 21, label: '21%' },
      ];

      // Validation setup
      const { inputs, validateForm, onValidate } = useValidation({
        title: {
          id: 'title',
          label: 'Proposal subject',
          binding: '',
          error: '',
          rules: ['required', 'maxLength:255'],
        },
        days_valid_amount: {
          id: 'days_valid_amount',
          label: 'Valid for (days)',
          binding: 30,
          error: '',
          rules: ['required', 'min:1'],
        },
        client_notes: {
          id: 'client_notes',
          label: 'Client notes',
          binding: '',
          error: '',
          rules: ['maxLength:2000'],
        },
        internal_notes: {
          id: 'internal_notes',
          label: 'Internal notes',
          binding: '',
          error: '',
          rules: ['maxLength:2000'],
        },
        auto_send_to_client: {
          id: 'auto_send_to_client',
          label: 'Automatically send proposal to lead',
          binding: false,
          error: '',
          rules: [],
        },
        proposal_mail_subject: {
          id: 'proposal_mail_subject',
          label: 'Proposal mail subject',
          binding: '',
          error: '',
          rules: ['required'],
        },
        proposal_mail_body: {
          id: 'proposal_mail_body',
          label: 'Proposal mail body',
          binding: '',
          error: '',
          rules: ['required'],
        },
      });

      // Item management
      const addItem = () => {
        items.value.binding.push({
          id: uuidv4(),
          description: '',
          quantity: 1,
          value: '',
          vat_rate: 21,
          linked_element: null,
        });
      };

      const removeItem = (index) => {
        items.value.binding.splice(index, 1);
      };

      const cloneItem = (index) => {
        const item = items.value.binding[index];
        items.value.binding.push({ ...item, id: uuidv4() });
      };

      const insertField = (field) => {
        items.value.binding.push({
          id: uuidv4(),
          description: field.label,
          quantity: 1,
          value: field.value,
          vat_rate: 21,
          linked_element_id: field.id,
        });
      };

      const validateItems = () => {
        let isValid = true;
        const validationErrors = {
          listError: '',
          itemErrors: [],
        };

        if (!items.value.binding.length) {
          validationErrors.listError = 'At least one item is required';
          items.value.error = validationErrors.listError;
          return false;
        }

        items.value.binding.forEach((item, index) => {
          const itemError = {
            error: '',
            errorType: '',
          };

          // if (item.value == undefined || item.value <= 0) {
          //   itemError.error = `Price must be greater than 0`;
          //   itemError.errorType = 'value';
          //   isValid = false;
          // }
          validationErrors.itemErrors[index] = itemError;
        });

        // Update the UI state once at the end
        items.value.error = validationErrors.listError;
        items.value.binding.forEach((item, index) => {
          item.error = validationErrors.itemErrors[index].error;
          item.errorType = validationErrors.itemErrors[index].errorType;
        });

        return isValid;
      };

      let isUpdating = false;

      watch(
        [() => items.value.binding, () => inputs.value],
        async (newVal, oldVal) => {
          if (isUpdating) return;

          try {
            isUpdating = true;
            await emitUpdate();
          } finally {
            isUpdating = false;
          }
        },
        { deep: true }
      );

      async function emitUpdate() {
        const isFormValid = await validateForm();
        const isItemsValid = validateItems();

        if (isFormValid && isItemsValid) {
          // Format the data properly before emitting
          const formData = {
            title: inputs.value.title.binding,
            days_valid_amount: inputs.value.days_valid_amount.binding,
            client_notes: inputs.value.client_notes.binding,
            internal_notes: inputs.value.internal_notes.binding,
            items: items.value.binding.map(({ error, errorType, ...item }) => item),
            auto_send_to_client: inputs.value.auto_send_to_client.binding,
            proposal_mail_subject: inputs.value.proposal_mail_subject.binding,
            proposal_mail_body: inputs.value.proposal_mail_body.binding,
          };
          emit('update:modelValue', formData);
        }
      }

      const formatDate = (date) => {
        return format(new Date(date), 'dd-MM-yyyy');
      };

      // Currency formatter
      const formatCurrency = (amount) => {
        return new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR',
        }).format(amount);
      };

      onMounted(() => {
        if (props.modelValue) {
          // Set input bindings
          inputs.value.title.binding = props.modelValue.title || '';
          inputs.value.days_valid_amount.binding = props.modelValue.days_valid_amount || 30;
          inputs.value.client_notes.binding = props.modelValue.client_notes || '';
          inputs.value.internal_notes.binding = props.modelValue.internal_notes || '';
          inputs.value.auto_send_to_client.binding = props.modelValue.auto_send_to_client || false;
          inputs.value.proposal_mail_subject.binding = props.modelValue.proposal_mail_subject || '';
          inputs.value.proposal_mail_body.binding = props.modelValue.proposal_mail_body || '';

          // Set items
          if (props.modelValue.items?.length) {
            items.value.binding = props.modelValue.items.map((item) => ({
              ...item,
              error: '',
              errorType: '',
            }));
          } else {
            addItem(); // Initialize with empty item if no items
          }
        } else {
          addItem(); // Initialize with empty item if no modelValue
        }
      });
      watch(
        () => props.modelValue,
        (newValue) => {
          if (newValue) {
            inputs.value.title.binding = newValue.title;
            inputs.value.days_valid_amount.binding = newValue.days_valid_amount;
            inputs.value.client_notes.binding = newValue.client_notes;
            inputs.value.internal_notes.binding = newValue.internal_notes;
            inputs.value.auto_send_to_client.binding = newValue.auto_send_to_client || false;
            inputs.value.proposal_mail_subject.binding =
              newValue.proposal_mail_subject ||
              organization.value.crm_settings.proposal_mail_subject;
            inputs.value.proposal_mail_body.binding =
              newValue.proposal_mail_body || organization.value.crm_settings.proposal_mail_body;
            if (newValue.items?.length) {
              items.value.binding = newValue.items.map((item) => ({
                ...item,
                error: '',
                errorType: '',
              }));
            }
          }
        },
        { deep: true }
      );

      return {
        inputs,
        items,
        formulaElements,
        choiceElements,
        vatRates,
        onValidate,
        addItem,
        removeItem,
        cloneItem,
        formatCurrency,
        formatDate,
        insertField,
        allElements,
      };
    },
  };
</script>

<style scoped lang="scss">
  .proposal-automation-config {
    .formula-picker {
      display: flex;
      flex-wrap: wrap;
      gap: 0.25rem;
      &__button {
        background: var(--brand-100);
        border: 1px solid var(--brand-200);
        color: var(--brand-600);
        padding: 0.25rem 0.5rem;
        cursor: pointer;
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        gap: 0.25rem;
        &:hover {
          opacity: 0.8;
        }
        &--formula {
          color: var(--green-500);
          background-color: var(--green-100);
          border-color: var(--green-500);
        }
        &--choice,
        &--single-choice,
        &--multiple-choice,
        &--picture-choice,
        &--dropdown {
          color: var(--orange-500);
          background-color: var(--orange-100);
          border-color: var(--orange-500);
        }
      }
    }
    .items-list {
      .element-tag {
        background: var(--brand-100);
        border: 1px solid var(--brand-200);
        color: var(--brand-600);
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        gap: 0.25rem;

        &--formula {
          color: var(--green-500);
          background-color: var(--green-100);
          border-color: var(--green-500);
        }
        &--choice,
        &--single-choice,
        &--multiple-choice,
        &--picture-choice,
        &--dropdown {
          color: var(--orange-500);
          background-color: var(--orange-100);
          border-color: var(--orange-500);
        }
      }
      &__list {
        .item-row {
          padding: 0.5rem;
          border: 1px solid var(--slate-200);
          border-radius: 0.5rem;
          margin-bottom: 1rem;
          &__content {
            display: grid;
            grid-template-columns: 2fr 1fr 1fr auto;
            gap: 1rem;
            align-items: start;

            .form-group {
              padding: 0;
            }
          }
          &__actions {
            display: flex;
            margin-top: 1rem;
            padding-top: 0.25rem;
            border-top: 1px solid var(--slate-200);
          }
          &--error {
            border-color: var(--red-500);
          }
          &__error {
            color: var(--red-500);
            font-size: 0.875rem;
            margin-top: 0.5rem;
          }
        }
      }
      &__error {
        color: var(--red-500);
        font-size: 0.875rem;
        margin-top: 0.5rem;
      }
    }

    .item-totals {
      font-size: 0.875rem;
      color: var(--slate-600);
      display: flex;
      flex-direction: column;
      height: 100%;
      .item-total {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    .totals {
      .summary-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        font-weight: 600;
      }
    }

    .form-actions {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      margin-top: 2rem;
    }

    .btn-add {
      width: 100%;
      margin-top: 1rem;
    }

    .btn-remove {
      color: var(--red-500);
      &:hover {
        color: var(--red-600);
      }
    }
  }
</style>
