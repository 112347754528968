<template>
  <div class="flow-editor">
    <div v-if="form" class="flow-editor__inner">
      <SidebarGeneral
        :form="form"
        :activePageIndex="activePageIndex"
        :activeElementIndex="activeElementIndex"
        :activePage="activePage"
        @remove-page="removePage"
        @edit-page-settings="editPageSettings"
        @set-active-page="setActivePage"
      />
      <div class="form-editor-wrap">
        <FormEditor
          :activePage="activePage"
          :activeElementIndex="activeElementIndex"
          @set-active-element="setActiveElement"
          @remove-element="removeElement"
          @add-new-input="setActiveElement(null)"
        />
        <FormPages :form="form" :activePageIndex="activePageIndex" :activePage="activePage" />
      </div>
      <SidebarElement v-if="activeElement" :activeElement="activeElement" />
    </div>
    <div v-else class="flow-editor__loading">
      <LoadingSpinner size="xl" color="dark" />
    </div>
  </div>
</template>

<script>
  import { ref, watchEffect, computed, onMounted, onUnmounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useFormStore } from '@/store/formStore';

  import SidebarGeneral from '@/components/editor/sidebar/general/SidebarGeneral.vue';
  import FormEditor from '@/components/editor/FormEditor.vue';
  import FormPages from '@/components/editor/FormPages.vue';
  import SidebarElement from '@/components/editor/sidebar/elements/SidebarElement.vue';
  import LoadingSpinner from '@/components/partials/LoadingSpinner.vue';
  export default {
    components: {
      SidebarGeneral,
      FormEditor,
      FormPages,
      SidebarElement,
      LoadingSpinner,
    },
    setup() {
      const route = useRoute();
      const formStore = useFormStore();
      const form = ref(null);

      watchEffect(() => {
        if (route.params.id) {
          formStore.loadForm(route.params.id).then(() => {
            form.value = formStore.currentForm;
            if (form.value.pages.length > 0) {
              formStore.setActivePage(0); // Set the first page as active by default
            }
          });
        }
      });

      const activePage = computed(() => formStore.activePage);
      const activePageIndex = computed(() => formStore.activePageIndex);
      const activeElementIndex = computed(() => formStore.activeElementIndex);
      const activeElement = computed(() => formStore.activeElement);

      watchEffect(() => {
        if (activeElement.value) {
          // Ensure element is selected in store
          formStore.setActiveElement(activePageIndex.value, activeElementIndex.value);
        }
      });

      const setActivePage = (pageIndex) => {
        formStore.setActivePage(pageIndex);
      };

      // When selecting an element
      const setActiveElement = (elementIndex) => {
        formStore.setActiveElement(activePageIndex.value, elementIndex);
      };

      const removeElement = async (elementIndex) => {
        await formStore.removeElement(elementIndex);
      };

      return {
        form,
        activePage,
        activePageIndex,
        activeElementIndex,
        removeElement,
        setActiveElement,
        setActivePage,
        activeElement,
      };
    },
  };
</script>

<style scoped lang="scss">
  .flow-editor {
    // display: flex;
    flex-grow: 1;
    overflow: auto;
    width: 100%;
    &__inner {
      display: flex;
      width: 100%;
      height: 100%;
    }
    &__loading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }
    .form-editor-wrap {
      flex: 1;
      display: flex;
      justify-content: center;
      height: 100%;
      overflow-y: auto;
      flex-direction: column;
    }
  }
</style>
