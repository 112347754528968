<template>
  <div class="proposal-rules-config">
    <!-- Field Mappings -->
    <div class="field-mappings">
      <h4>Form Field Mappings</h4>
      <div
        v-for="(mapping, index) in modelValue.fieldMappings"
        :key="index"
        class="field-mapping mt-4 p-4 border rounded"
      >
        <!-- Field Selection -->
        <SelectInput
          :id="`field-select-${index}`"
          label="Form Field"
          :options="formFields"
          v-model="mapping.fieldId"
          placeholder="Select form field"
          @update:model-value="emitUpdate"
        />

        <!-- Pricing Type -->
        <SelectInput
          :id="`pricing-type-${index}`"
          label="Pricing Type"
          :options="pricingTypes"
          v-model="mapping.pricingType"
          class="mt-2"
          @update:model-value="emitUpdate"
        />

        <!-- Pricing Rules based on type -->
        <div class="mt-2">
          <component
            :is="getPricingComponent(mapping.pricingType)"
            v-if="mapping.pricingType"
            :mapping="mapping"
            :index="index"
            :form-fields="formFields"
            @update="emitUpdate"
          />
        </div>

        <!-- Description Template -->
        <TextInput
          :id="`description-template-${index}`"
          label="Description Template"
          v-model="mapping.descriptionTemplate"
          placeholder="e.g.: {{value}}m² surface treatment"
          class="mt-2"
          @update:model-value="emitUpdate"
        />

        <Btn @click="removeFieldMapping(index)" variant="danger" class="mt-4">Remove Mapping</Btn>
      </div>

      <Btn @click="addFieldMapping" variant="secondary" class="mt-4">Add Field Mapping</Btn>
    </div>

    <!-- Fixed Items -->
    <div class="fixed-items mt-4">
      <h4>Additional Fixed Items</h4>
      <div v-for="(item, index) in modelValue.fixedItems" :key="index" class="fixed-item mt-2">
        <div class="flex gap-4">
          <TextInput
            :id="`fixed-item-desc-${index}`"
            label="Description"
            v-model="item.description"
            class="flex-grow"
            @update:model-value="emitUpdate"
          />
          <TextInput
            :id="`fixed-item-price-${index}`"
            label="Price"
            v-model="item.price"
            type="number"
            step="0.01"
            class="w-32"
            @update:model-value="emitUpdate"
          />
          <Btn @click="removeFixedItem(index)" variant="danger" class="mt-auto mb-2">Remove</Btn>
        </div>
      </div>
      <Btn @click="addFixedItem" variant="secondary" class="mt-2">Add Fixed Item</Btn>
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue';
  import { useAutomationStore } from '@/store/automationStore';
  import SelectInput from '@/components/inputs/SelectInput.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import FixedPricing from './pricing/FixedPricing.vue';
  import PerUnitPricing from './pricing/PerUnitPricing.vue';
  import RangePricing from './pricing/RangePricing.vue';
  import ValueBasedPricing from './pricing/ValueBasedPricing.vue';
  import Btn from '@/components/partials/Btn.vue';

  export default {
    name: 'ProposalRulesConfig',

    components: {
      SelectInput,
      TextInput,
      FixedPricing,
      PerUnitPricing,
      RangePricing,
      ValueBasedPricing,
      Btn,
    },

    props: {
      modelValue: {
        type: Object,
        required: true,
      },
    },

    setup(props, { emit }) {
      const automationStore = useAutomationStore();

      const pricingTypes = [
        { label: 'Fixed Price', value: 'fixed' },
        { label: 'Price per Unit', value: 'per_unit' },
        { label: 'Range Based', value: 'range' },
        { label: 'Value Based', value: 'value_based' },
      ];

      const formFields = computed(() => {
        // Get form fields from trigger details
        if (!automationStore.currentAutomation?.trigger_details?.formDetails?.pages) {
          return [];
        }

        // Flatten all elements from all pages and filter out headings and text elements
        const fields = automationStore.currentAutomation.trigger_details.formDetails.pages
          .flatMap((page) => page.elements)
          .filter((element) => element.type !== 'heading' && element.type !== 'text')
          .map((element) => ({
            label: element.props.label,
            value: element.id,
          }));

        return fields;
      });

      const getPricingComponent = (type) => {
        const components = {
          fixed: 'FixedPricing',
          per_unit: 'PerUnitPricing',
          range: 'RangePricing',
          value_based: 'ValueBasedPricing',
        };
        return components[type];
      };

      const addFieldMapping = () => {
        const newMapping = {
          fieldId: '',
          pricingType: 'fixed',
          fixedPrice: 0,
          unitPrice: 0,
          rangeRules: [],
          valueRules: [],
          descriptionTemplate: '',
        };
        emit('update:modelValue', {
          ...props.modelValue,
          fieldMappings: [...props.modelValue.fieldMappings, newMapping],
        });
      };

      const removeFieldMapping = (index) => {
        const newMappings = [...props.modelValue.fieldMappings];
        newMappings.splice(index, 1);
        emit('update:modelValue', {
          ...props.modelValue,
          fieldMappings: newMappings,
        });
      };

      const addFixedItem = () => {
        emit('update:modelValue', {
          ...props.modelValue,
          fixedItems: [...props.modelValue.fixedItems, { description: '', price: 0 }],
        });
      };

      const removeFixedItem = (index) => {
        const newItems = [...props.modelValue.fixedItems];
        newItems.splice(index, 1);
        emit('update:modelValue', {
          ...props.modelValue,
          fixedItems: newItems,
        });
      };

      const emitUpdate = () => {
        emit('update:modelValue', { ...props.modelValue });
      };

      return {
        pricingTypes,
        formFields,
        getPricingComponent,
        addFieldMapping,
        removeFieldMapping,
        addFixedItem,
        removeFixedItem,
        emitUpdate,
      };
    },
  };
</script>

<style scoped lang="scss">
  .proposal-rules-config {
    .field-mapping {
      border: 1px solid var(--slate-200);
      border-radius: 0.25rem;
    }
    .fixed-items {
      border-top: 1px solid var(--slate-200);
      margin-top: 1rem;
      padding-top: 1rem;
    }
  }
</style>
