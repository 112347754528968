<template>
  <div class="sf-el address address-input">
    <div class="sf-address">
      <label class="sf-el__label">
        {{ label }}
        <span v-if="required" class="sf-el__required">*</span>
      </label>

      <div class="address__fields">
        <div class="address__field-row address__field-row--no-break">
          <div class="address__field address__field--street">
            <input type="text" placeholder="Street" class="address__input" />
          </div>
          <div class="address__field address__field--number">
            <input type="text" placeholder="House number" class="address__input" />
          </div>
        </div>
        <div class="address__field-row">
          <div class="address__field">
            <input type="text" placeholder="Postal code" class="address__input" />
          </div>
          <div class="address__field">
            <input type="text" placeholder="City" class="address__input" />
          </div>

          <div class="address__field">
            <select class="address__input" placeholder="Select country">
              <option value="" class="placeholder" disabled>Select country</option>
              <option v-for="country in countries" :key="country.value" :value="country.value">
                {{ country.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { watch, ref } from 'vue';
  export default {
    name: 'EmailElement',
    props: {
      label: String,
      placeholder: String,
      modelValue: String,
      required: Boolean,
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const value = ref(props.modelValue);
      watch(value, (newValue) => {
        emit('update:modelValue', newValue);
      });
      return {
        value,
      };
    },
  };
</script>

<style scoped lang="scss">
  .address {
    &__fields {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__field {
      width: 100%;

      &--number {
        width: 120px;
        flex-shrink: 0;
      }

      &--postal {
        width: 120px;
        flex-shrink: 0;
      }
    }

    &__field-row {
      display: flex;
      gap: 16px;
      @include lg-down {
        flex-direction: column;
      }
      &--no-break {
        @include lg-down {
          flex-direction: row;
        }
      }
    }
  }
</style>
