<template>
  <div class="sf-el switch-input">
    <div class="sf-switch">
      <label class="sf-el__label">
        {{ label }}
        <span v-if="required" class="sf-el__required">*</span>
      </label>
      <div class="toggle">
        <input type="checkbox" v-model="value" :id="label" />
        <label :for="label">
          <span class="toggle__dot"></span>
          <span class="toggle__area"></span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
  import { watch, ref } from 'vue';
  export default {
    name: 'SwitchElement',
    props: {
      modelValue: {
        type: [Boolean, String],
        default: false,
        validator(value) {
          return (
            typeof value === 'boolean' || value === 'true' || value === 'false' || value === ''
          );
        },
      },
      label: String,
      required: Boolean,
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const value = ref(false);

      // More explicit initialization
      const initializeValue = (inputValue) => {
        if (inputValue === true || inputValue === 'true') {
          return true;
        }
        // Explicitly handle empty string case
        if (
          inputValue === '' ||
          inputValue === false ||
          inputValue === 'false' ||
          inputValue === null ||
          inputValue === undefined
        ) {
          return false;
        }
        return false;
      };

      // Initialize
      value.value = initializeValue(props.modelValue);

      // Watch for changes
      watch(
        () => props.modelValue,
        (newValue) => {
          value.value = initializeValue(newValue);
        }
      );

      watch(value, (newValue) => {
        emit('update:modelValue', newValue);
      });

      return {
        value,
      };
    },
  };
</script>

<style scoped lang="scss">
  .sf-switch {
    .toggle {
      position: relative;
      user-select: none;

      input[type='checkbox'] {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;

        &:checked + label {
          background-color: var(--brand-500);
        }
      }

      label {
        display: block;
        height: 1.3125rem;
        width: 2.25rem;
        cursor: pointer;
        overflow: hidden;
        border-radius: 9999px;
        background-color: rgba(0, 0, 0, 0.25);
        position: relative;
        transition: background-color 0.15s ease-out;

        & > span {
          margin: 0 !important;
        }

        .toggle__dot {
          position: absolute;
          display: block;
          border-radius: 9999px;
          width: 14px;
          height: 14px;
          top: 50%;
          left: 4px;
          transform: translateY(-50%);
          transition: all 0.15s ease-out;
          background-color: var(--white);
        }

        .toggle__area {
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          white-space: nowrap;
          border-width: 0;
        }

        &:hover {
          background-color: var(--slate-400);
        }
      }

      input[type='checkbox']:checked + label {
        background-color: var(--cs-brand-primary);

        .toggle__dot {
          left: 18px;
        }
      }
    }
  }
</style>
