<template>
  <div class="form-card" @click="router.push(`/flow/${form.id}`)">
    <h2 class="form-card__title">
      {{ form.name }}
    </h2>
    <div class="form-card__subtitle">
      {{ formatDate(form.created_at) }}
    </div>
    <div class="form-card__actions">
      <DropdownMenu @click.stop.prevent>
        <template #trigger>
          <Btn variant="plain" :disabled="!isAdmin">
            <BaseIcon name="dots" size="18" />
          </Btn>
        </template>
        <template #items>
          <div class="dropdown-menu__item" @click.stop="router.push(`/flow/${form.id}`)">
            Edit funnel
          </div>
          <div class="dropdown-menu__item" @click.stop="copyShareLink">
            <BaseIcon name="link" size="14" />
            Copy share link
          </div>
          <div class="dropdown-menu__item dropdown-menu__item--danger" @click.stop="deleteForm">
            Delete funnel
          </div>
        </template>
      </DropdownMenu>
      <!-- <Btn
        text="Edit flow"
        @click="router.push(`/flow/${form.id}`)"
        variant="secondary"
        :disabled="!isAdmin"
      />
      <BaseIcon
        name="trash"
        @click="deleteForm"
        size="12"
        class="form-card__delete"
        v-if="isAdmin"
      /> -->
    </div>
  </div>
</template>

<script>
  import { useRoleCheck } from '@/composables/useRoleCheck';
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useFormStore } from '@/store/formStore';
  import { useUIStore } from '@/store/uiStore';
  import { useToastStore } from '@/store/toastStore';
  import useDayJs from '@/utils/useDayJs';
  import { formatDate } from '@/utils/date';
  import Btn from '@/components/partials/Btn.vue';
  import BaseIcon from '@shared/components/BaseIcon.vue';
  import DropdownMenu from '@/components/partials/DropdownMenu.vue';
  export default {
    name: 'FormCard',
    components: {
      Btn,
      BaseIcon,
      DropdownMenu,
    },
    props: {
      form: {
        type: String,
      },
    },
    setup(props) {
      const router = useRouter();
      const formStore = useFormStore();
      const uiStore = useUIStore();
      const toastStore = useToastStore();
      const { isAdmin } = useRoleCheck();
      const deleteForm = () => {
        const modalId = uiStore.addModal(null, {
          title: 'Delete flow',
          description: 'Are you sure you want to delete this flow? This action cannot be undone.',
          status: 'danger',
          onSubmit: async () => {
            await formStore.deleteForm(props.form.id);
            uiStore.closeModal(modalId);
          },
        });
      };

      const copyShareLink = () => {
        const shareLink = `https://view.getsmartflow.nl/form/${props.form.id}`;
        navigator.clipboard.writeText(shareLink);
        toastStore.addToast({
          message: 'Share link has been copied to clipboard',
          type: 'success',
        });
      };

      return {
        router,
        deleteForm,
        formatDate,
        isAdmin,
        copyShareLink,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/base/mixins';
  .form-card {
    position: relative;
    box-shadow:
      0 0 #0000,
      0 0 #0000,
      0 1px 2px 0 rgb(0 0 0 / 0.05);
    background-color: #ffffff;
    padding: 1rem 1.5rem 1rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid transparent;
    @include lg-down {
      padding: 0.75rem 1.5rem 0.75rem 0.75rem;
    }
    @include hover {
      box-shadow:
        0 0 #0000,
        0 0 #0000,
        0 1px 2px 0 rgb(0 0 0 / 0.05);
      border: 1px solid var(--slate-200);
    }
    &__title {
      font-weight: 600;
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
    }
    &__subtitle {
      color: var(--slate-500);
    }
    &__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 0.75rem;
      position: absolute;
      top: 0.5rem;
      right: 1rem;
    }
    &__delete {
      color: var(--slate-500);
      cursor: pointer;
      @include hover {
        color: var(--red-500);
      }
    }
  }
</style>
