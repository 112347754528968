<template>
  <div class="proposal" v-if="proposal">
    <div class="proposal__company-info">
      <div class="proposal__logo">
        <img
          v-if="proposal.organizations?.brand_settings?.logo"
          :src="proposal.organizations.brand_settings.logo"
          alt="Logo"
        />
        <h2 v-else>{{ proposal.organizations?.name }}</h2>
      </div>
      <div class="proposal__company-details">
        <p v-if="proposal.organizations?.name">
          {{ proposal.organizations.name }}
        </p>
        <p v-if="proposal.organizations?.address?.street_address">
          {{ proposal.organizations.address.street_address }}
        </p>
        <p
          v-if="
            proposal.organizations?.address?.postal_code && proposal.organizations?.address?.city
          "
        >
          {{ proposal.organizations.address.postal_code }} {{ proposal.organizations.address.city }}
        </p>
        <p v-if="proposal.organizations?.address?.phone">
          {{ proposal.organizations.address.phone }}
        </p>
        <p v-if="proposal.organizations?.address?.registration_number">
          KvK: {{ proposal.organizations.address.registration_number }}
        </p>
        <p v-if="proposal.organizations?.address?.bank_account">
          IBAN: {{ proposal.organizations.address.bank_account }}
        </p>
      </div>
    </div>

    <div class="proposal__client-info">
      <p>{{ proposal.leads.company_name || '' }}</p>
      <p v-if="proposal.leads.first_name && proposal.leads.last_name">
        T.a.v. {{ proposal.leads.first_name }} {{ proposal.leads.last_name }}
      </p>
      <p>{{ proposal.leads.email }}</p>
      <p>{{ proposal.leads.phone }}</p>
      <div v-if="proposal.leads.address" class="proposal__client-address">
        <p v-if="proposal.leads.address.street && proposal.leads.address.houseNumber">
          {{ proposal.leads.address.street }} {{ proposal.leads.address.houseNumber }}
        </p>
        <p v-if="proposal.leads.address.postalCode && proposal.leads.address.city">
          {{ proposal.leads.address.postalCode }} {{ proposal.leads.address.city }}
        </p>
        <p v-if="proposal.leads.address.country">
          <BaseIcon name="loc" size="12" />
          {{ proposal.leads.address.country }}
        </p>
      </div>
    </div>

    <div class="proposal__reference">
      <h1>{{ proposal.title }}</h1>
      <div class="proposal__dates">
        <div>
          <span>Offertedatum:</span>
          <span>{{ formatDate(proposal.proposal_date) }}</span>
        </div>
        <div>
          <span>Vervaldatum:</span>
          <span>{{ formatDate(proposal.valid_until) }}</span>
        </div>
      </div>
    </div>

    <div class="proposal__items">
      <table class="proposal__table">
        <thead>
          <tr>
            <th>Omschrijving</th>
            <th class="proposal__table-cell--right">Aantal</th>
            <th class="proposal__table-cell--right">Bedrag</th>
            <th class="proposal__table-cell--right">BTW</th>
            <th class="proposal__table-cell--right">Totaal</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in proposal.items" :key="item.id">
            <td>{{ item.description }}</td>
            <td class="proposal__table-cell--right proposal__table-cell--quantity">
              {{ item.quantity }} x
            </td>
            <td class="proposal__table-cell--right proposal__table-cell--price">
              {{ formatCurrency(item.unit_price) }}
            </td>
            <td class="proposal__table-cell--right proposal__table-cell--vat">
              {{ item.vat_rate }}%
            </td>
            <td class="proposal__table-cell--right proposal__table-cell--total">
              {{ formatCurrency(item.quantity * item.unit_price) }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="proposal__table-cell--hide-mobile"></td>
            <td class="proposal__table-cell--hide-mobile"></td>
            <td></td>
            <td class="proposal__table-footer-label"><strong>Subtotaal</strong></td>
            <td class="proposal__table-cell--right">{{ formatCurrency(subtotal) }}</td>
          </tr>
          <tr>
            <td class="proposal__table-cell--hide-mobile"></td>
            <td class="proposal__table-cell--hide-mobile"></td>
            <td></td>
            <td class="proposal__table-footer-label"><strong>BTW</strong></td>
            <td class="proposal__table-cell--right">{{ formatCurrency(vatAmount) }}</td>
          </tr>
          <tr>
            <td class="proposal__table-cell--hide-mobile"></td>

            <td class="proposal__table-cell--hide-mobile"></td>
            <td></td>
            <td class="proposal__table-footer-label"><strong>Totaal</strong></td>
            <td class="proposal__table-cell--right">{{ formatCurrency(total) }}</td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div v-if="proposal.client_notes" class="proposal__notes">
      <h3 class="proposal__notes-title">Opmerkingen</h3>
      <p class="proposal__notes-content">{{ proposal.client_notes }}</p>
    </div>

    <div class="proposal__terms">
      <p>
        Door akkoord te gaan met deze offerte gaat u tevens akkoord met onze algemene voorwaarden.
      </p>
    </div>

    <div v-if="proposal.accepted_at" class="proposal__accepted">
      <p>
        Deze offerte is goedgekeurd op {{ formatDateTime(proposal.accepted_at) }} door
        {{ proposal.signature_data.signer_name }} ({{ proposal.signature_data.signer_email }})
      </p>
      <div class="proposal__accepted-signature">
        <img :src="proposal.signature_data.signature" alt="Signature" />
      </div>
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue';
  import dayjs from 'dayjs';

  export default {
    name: 'ProposalPreview',
    props: {
      proposal: {
        type: Object,
        required: true,
        validator: (prop) => {
          return prop.organizations && prop.leads && prop.items;
        },
      },
    },
    setup(props) {
      const subtotal = computed(() => {
        return props.proposal.items.reduce((sum, item) => {
          return sum + item.quantity * item.unit_price;
        }, 0);
      });

      const vatAmount = computed(() => {
        return props.proposal.items.reduce((sum, item) => {
          return sum + item.quantity * item.unit_price * (item.vat_rate / 100);
        }, 0);
      });

      const total = computed(() => subtotal.value + vatAmount.value);

      const formatCurrency = (amount) => {
        return new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: props.proposal.currency || 'EUR',
        }).format(amount);
      };

      const formatDate = (date) => {
        return dayjs(date).format('DD-MM-YYYY');
      };

      const formatDateTime = (date) => {
        return dayjs(date).format('DD-MM-YYYY h:mm A');
      };

      return {
        subtotal,
        vatAmount,
        total,
        formatCurrency,
        formatDate,
        formatDateTime,
      };
    },
  };
</script>

<style scoped lang="scss">
  .proposal {
    background: white;
    padding: 2rem;
    max-width: 1000px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    @include lg-down {
      padding: 0.25rem;
    }

    &__company-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4rem;
      @include lg-down {
        flex-direction: column;
        gap: 1rem;
      }
    }

    &__logo {
      h2 {
        font-size: 1.5rem;
        margin: 0;
      }
      max-width: 200px;
      img {
        max-width: 100%;
      }
    }

    &__company-details {
      text-align: right;
      @include lg-down {
        text-align: left;
      }

      p {
        margin: 0.25rem 0;
        font-size: 0.9rem;
      }
    }

    &__client-info {
      margin-bottom: 4rem;

      p {
        margin: 0.25rem 0;
      }
    }

    &__reference {
      margin-bottom: 3rem;

      h1 {
        font-size: 1.75rem;
        margin-bottom: 1rem;
      }
    }

    &__dates {
      display: flex;
      gap: 2rem;

      span:first-child {
        font-weight: 600;
        margin-right: 0.5rem;
      }
      @include lg-down {
        flex-direction: column;
        gap: 0.5rem;
      }
    }

    &__items {
      margin: 2rem 0;
    }

    &__table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 2rem;
      table-layout: fixed;

      th,
      td {
        padding: 1rem;
        border-bottom: 1px solid #eee;
      }

      // Desktop layout
      th:nth-child(1),
      td:nth-child(1) {
        // width: 40%;
        text-align: left;
      }

      th:nth-child(2),
      td:nth-child(2) {
        // width: 10%;
        text-align: right;
      }

      th:nth-child(3),
      td:nth-child(3) {
        // width: 30%;
        text-align: right;
      }

      th:nth-child(4),
      td:nth-child(4) {
        // width: 10%;
        text-align: right;
      }

      th:nth-child(5),
      td:nth-child(5) {
        // width: 10%;
        text-align: right;
      }

      // Hide price and VAT columns on mobile
      .proposal__table-cell--price,
      .proposal__table-cell--vat {
        @include lg-down {
          display: none;
        }
      }

      // Footer specific styles
      tfoot {
        tr {
          td {
            padding: 0.5rem 1rem;
            @include lg-down {
              padding: 0.25rem;
            }
          }

          td:first-of-type {
            text-align: right;
            @include lg-down {
              text-align: left;
            }
          }

          td:last-of-type {
            width: 30%;
            text-align: right;
            white-space: nowrap;
          }
        }
      }

      @include lg-down {
        td,
        th {
          padding: 0.5rem;
        }
      }
    }

    &__table-cell--right {
      text-align: right;
    }
    &__table-cell {
      &--price {
        @include lg-down {
          display: none;
        }
      }
      &--vat {
        @include lg-down {
          display: none;
        }
      }
      &--hide-mobile {
        @include lg-down {
          display: none;
        }
      }
    }

    &__notes {
      margin-top: 2rem;
      padding-top: 2rem;
      border-top: 1px solid #eee;
    }

    &__notes-title {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }

    &__notes-content {
      line-height: 1.5;
    }

    &__terms {
      margin-top: 3rem;
      padding-top: 2rem;
      border-top: 1px solid #eee;
      font-size: 0.9rem;
      color: #666;
    }

    table {
      thead {
        @include lg-down {
          display: none;
        }
      }
      tbody {
        tr {
          td {
            @include lg-down {
              padding: 0.25rem;
            }
          }
        }
      }
      // tfoot {
      //   tr {
      //     td {
      //       padding: 0.5rem 1rem;
      //       white-space: nowrap;
      //     }

      //     td:first-of-type {
      //       white-space: normal;
      //     }

      //     &:last-child {
      //       font-weight: bold;
      //       font-size: 1.1rem;
      //     }
      //   }
      //   @include lg-down {
      //     td {
      //       padding: 0.25rem;
      //     }

      //     // Make the amount column width fixed
      //     td:last-of-type {
      //       width: 120px; // Adjust this value based on your needs
      //     }
      //   }
      // }
    }

    &__accepted {
      margin-top: 3rem;
      padding-top: 2rem;
      border-top: 1px solid #eee;
    }

    &__accepted-signature {
      img {
        width: 200px;
        max-width: 100%;
      }
    }
  }
</style>
