<template>
  <div class="reset-password-page">
    <div class="container">
      <div class="card">
        <form @submit.prevent="updatePassword" v-if="!passwordUpdated">
          <h2 class="card__heading">Reset password</h2>
          <TextInput id="new-password" label="New password" v-model="newPassword" type="password" />
          <TextInput
            id="confirm-password"
            label="Confirm password"
            v-model="confirmPassword"
            type="password"
          />
          <Btn
            type="submit"
            :loading="isLoading"
            variant="secondary"
            class="width-100 mt-2"
            text="Update Password"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, onUnmounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { supabase } from '@/services/supabase';
  import { useUIStore } from '@/store/uiStore';
  import TextInput from '@/components/inputs/TextInput.vue';
  import Btn from '@/components/partials/Btn.vue';

  export default {
    components: {
      TextInput,
      Btn,
    },
    setup() {
      const router = useRouter();
      const uiStore = useUIStore();
      const newPassword = ref('');
      const confirmPassword = ref('');
      const isLoading = ref(false);
      const passwordUpdated = ref(false);
      onMounted(async () => {
        const {
          data: { session },
        } = await supabase.auth.getSession();

        // Add the auth state listener
        const {
          data: { subscription },
        } = supabase.auth.onAuthStateChange((event, session) => {
          if (event === 'USER_UPDATED') {
            passwordUpdated.value = true;
            uiStore.addModal(null, {
              title: 'Password Updated',
              description:
                'Your password has been successfully updated. Please log in with your new password.',
            });

            // Sign out the user after password change
            supabase.auth.signOut();
            router.push('/login');
          }
        });

        // Cleanup subscription on component unmount
        onUnmounted(() => {
          subscription.unsubscribe();
        });
      });

      const updatePassword = async () => {
        if (newPassword.value !== confirmPassword.value) {
          uiStore.addModal(null, {
            title: 'Passwords do not match',
            description: 'Please ensure both passwords match.',
          });
          return;
        }
        isLoading.value = true;

        try {
          // Check session with logging
          const { data: sessionData, error: sessionError } = await supabase.auth.getSession();

          if (!sessionData.session) {
            throw new Error('No active session found');
          }

          const { data, error } = await supabase.auth.updateUser({
            password: newPassword.value,
          });

          if (error) throw error;
        } catch (error) {
          console.error('Password reset error:', error.message);
          uiStore.addModal(null, {
            title: 'Error',
            description: error.message,
          });
        } finally {
          isLoading.value = false;
        }
      };

      return {
        newPassword,
        confirmPassword,
        isLoading,
        passwordUpdated,
        updatePassword,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .reset-password-page {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .card {
      max-width: 400px;
      margin: 0 auto;
    }
  }
</style>
