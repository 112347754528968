<template>
  <div class="toolbar">
    <div class="toolbar__inner">
      <div class="toolbar__heading">
        <div class="toolbar__heading-back">
          <router-link to="/all-flows">
            <Btn variant="plain">
              <BaseIcon name="home" size="16" />
            </Btn>
          </router-link>
        </div>
        <InplaceEdit
          :label="`<h3>${form.name}</h3>`"
          :value="form.name"
          @update="updateFormName($event)"
        ></InplaceEdit>
        <div class="toolbar__save-section">
          <span v-if="lastSaveTime" class="toolbar__save-status">
            <template v-if="formStore.isSaving">
              <LoadingSpinner />
              Saving...
            </template>
            <template v-else-if="formStore.isDirty">
              {{ isMac ? '⌘' : 'Ctrl' }} + S to save
            </template>
            <template v-else>Last saved at {{ formatTime(lastSaveTime) }}</template>
          </span>
        </div>
      </div>
      <nav class="toolbar__nav">
        <ul class="toolbar__nav-list">
          <li class="toolbar__link toolbar__link--edit">
            <router-link :to="{ name: 'Edit', params: { id: $route.params.id } }">
              Edit
              <BaseIcon name="edit" size="16" />
            </router-link>
          </li>
          <li class="toolbar__link toolbar__link--integrations">
            <router-link :to="{ name: 'FormIntegrations', params: { id: $route.params.id } }">
              Integrations
              <BaseIcon name="cubes" size="16" />
            </router-link>
          </li>
          <li class="toolbar__link toolbar__link--submissions">
            <router-link :to="{ name: 'Submissions', params: { id: $route.params.id } }">
              Submissions
              <BaseIcon name="crm" size="16" />
            </router-link>
          </li>
          <li class="toolbar__link toolbar__link--settings">
            <router-link :to="{ name: 'Settings', params: { id: $route.params.id } }">
              Settings
              <BaseIcon name="settings" size="16" />
            </router-link>
          </li>
          <li class="toolbar__link toolbar__link--has-btn">
            <SplitBtn
              icon="zap"
              @click="publishForm"
              @menu-item-click="handleMenuItemClick"
              :variant="canPublish ? 'secondary' : 'tertiary'"
              :disabled="formStore.isPublishing"
              :mainBtnDisabled="!canPublish"
              :loading="formStore.isPublishing"
              class="publish-btn"
              :class="{ 'publish-btn--published': form.published_at }"
              :menu-items="publishMenuItems"
            >
              <div class="publish-btn__dot"></div>
              {{ publishButtonText }}
            </SplitBtn>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
  import { useFormStore } from '@/store/formStore';
  import { computed, ref, onMounted, onUnmounted, watch } from 'vue';
  import { useToastStore } from '@/store/toastStore';
  import { useDebounce } from '@/composables/useDebounce';
  import { useConfetti } from '@/services/confettiService';

  // import { useRouter } from 'vue-router';
  import PublishFunnelForm from '@/components/editor/PublishFunnelForm.vue';
  import { useUIStore } from '@/store/uiStore';
  import BaseIcon from '@shared/components/BaseIcon.vue';
  import Btn from '@/components/partials/Btn.vue';
  import SplitBtn from '@/components/partials/SplitBtn.vue';
  import LoadingSpinner from '@/components/partials/LoadingSpinner.vue';
  import InplaceEdit from '@/components/partials/InplaceEdit.vue';
  export default {
    name: 'EditorToolbar',
    props: {
      formName: {
        type: String,
        default: '',
      },
    },
    components: {
      BaseIcon,
      Btn,
      LoadingSpinner,
      SplitBtn,
      InplaceEdit,
    },
    setup() {
      const formStore = useFormStore();
      const toastStore = useToastStore();
      const form = computed(() => formStore.currentForm);
      const confetti = useConfetti();

      const lastSaveTime = computed(() => formStore.lastSaveTime);

      const isMac = computed(() => {
        return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      });

      const canPublish = computed(() => {
        // Form is not published yet
        if (!form.value.published_at) {
          return true;
        }

        // Form is published and has draft changes
        if (form.value.published_at && (form.value.has_draft || formStore.isDirty)) {
          return true;
        }

        // Form is published but has no draft changes
        return false;
      });

      const publishButtonText = computed(() => {
        if (!form.value.published_at) {
          return 'Publish';
        }
        if (form.value.has_draft) {
          return 'Publish changes';
        }
        return 'Published';
      });

      const publishMenuItems = computed(() => {
        return form.value.published_at
          ? [
              {
                label: 'Publish changes',
                value: 'publish-changes',
                disabled: !form.value.has_draft,
              },
              { label: 'Share settings', value: 'share-settings' },
              { label: 'Unpublish', value: 'unpublish' },
            ]
          : [];
      });

      watch(
        () => form.value,
        (newData) => {
          formStore.isDirty = formStore.checkIfDirty(newData);
        },
        { deep: true }
      );

      // Debounced save function
      const debouncedSave = useDebounce(async () => {
        if (formStore.isDirty) {
          await formStore.saveCurrentForm();
        }
      }, 30000);

      // Optional: Auto-save on changes
      watch(
        () => formStore.isDirty,
        (isDirty) => {
          if (isDirty) {
            debouncedSave();
          }
        }
      );

      async function updateFormName(name) {
        await formStore.updateFormName(name);
      }

      async function publishForm() {
        try {
          // Set loading state
          formStore.isPublishing = true;

          // First save any unsaved changes
          if (formStore.isDirty) {
            await handleSave();
          }

          // If not published, open the modal. Otherwise, publish changes directly
          const wasPublished = form.value.published_at;

          const publishedForm = await formStore.publishChanges(form.value.id);
          toastStore.addToast({
            message: 'Changes published!',
            type: 'success',
            duration: 3000,
          });
          confetti.fire();
          // If the form was not published, open the embed modal first
          if (!wasPublished) {
            openPublishModal();
          }
        } catch (error) {
          toastStore.addToast({
            message: 'Failed to publish form',
            type: 'error',
            duration: 3000,
          });
          console.error('Failed to publish:', error);
        } finally {
          // Reset loading state
          formStore.isPublishing = false;
        }
      }
      function openPublishModal() {
        const uiStore = useUIStore();
        const modalId = uiStore.addModal(PublishFunnelForm, {
          title: 'Share your flow',
          description: 'You can share your flow with a link or embed code.',
          size: 'lg',
          onSubmit: async (data) => {
            uiStore.closeModal(modalId);
          },
        });
      }

      const formatTime = (date) => {
        if (!date) return '';
        return new Intl.DateTimeFormat('default', {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        }).format(date);
      };

      const handleSave = async () => {
        try {
          await formStore.saveCurrentForm();
          toastStore.addToast({
            message: 'Form saved!',
            type: 'success',
            duration: 3000,
          });
        } catch (error) {
          console.error('Failed to save:', error);
        }
      };

      const handleKeyboard = (e) => {
        // Check if CMD/CTRL is pressed
        if (e.metaKey || e.ctrlKey) {
          switch (e.key.toLowerCase()) {
            case 'z':
              e.preventDefault();
              if (e.shiftKey) {
                formStore.redo();
              } else {
                formStore.undo();
              }
              break;
            case 's':
              handleSave();
              e.preventDefault();
              break;
          }
        }
      };

      async function handleMenuItemClick(item) {
        console.log(item);
        if (item === 'publish-changes') {
          publishForm();
        } else if (item === 'share-settings') {
          openPublishModal();
        } else if (item === 'unpublish') {
          await formStore.unpublishForm(form.value.id);
        }
      }

      onMounted(() => {
        window.addEventListener('keydown', handleKeyboard);
      });

      onUnmounted(() => {
        window.removeEventListener('keydown', handleKeyboard);
      });

      return {
        openPublishModal,
        form,
        lastSaveTime,
        formatTime,
        handleSave,
        isMac,
        canPublish,
        publishButtonText,
        publishForm,
        formStore,
        publishMenuItems,
        handleMenuItemClick,
        updateFormName,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/base/mixins';
  .toolbar {
    width: 100%;
    position: relative;
    padding: 0 1rem;
    background-color: var(--white);
    @include lg-down {
      padding: 0;
    }
    &__heading {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      h2 {
        @include lg-down {
          font-size: 1rem;
          white-space: nowrap;
          margin-right: 1rem;
        }
      }
    }
    &__save-section {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: var(--slate-500);
      @include lg-down {
        display: none;
      }
    }
    &__save-icon {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      @include fontSize('sm');
      border: 1px solid var(--slate-200);
      padding: 0.25rem 0.25rem;
      border-radius: 0.5rem;
      margin-left: 0.5rem;
    }
    &__save-status {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    &__inner {
      padding: 1rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include lg-down {
        overflow-x: auto;
        padding: 1rem 1rem;
        overflow-y: visible;
      }
    }
    &__nav {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    &__nav-list {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      li {
        a {
          padding: 7px 10px;
          border-radius: 0.5rem;
          border: 1px solid transparent;
          color: var(--slate-500);
          display: flex;
          align-items: center;
          gap: 0.5rem;
          @include hover {
            cursor: pointer;
            background-color: var(--slate-50);
          }
          &.router-link-exact-active {
            background-color: var(--slate-100);
            border-color: var(--slate-200);
            color: var(--slate-900);
          }
        }
        @include fontSize('md');
        font-weight: 500;
        &.toolbar__link--edit,
        &.toolbar__link--integrations,
        &.toolbar__link--settings {
          @include lg-down {
            display: none;
          }
        }
      }
    }
    .publish-btn {
      --color: var(--slate-200);
      &--published {
        --color: var(--green-500);
      }
      &__dot {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: var(--color);
        margin-right: 0.5rem;
        flex-shrink: 0;
      }
    }
  }
</style>
