<template>
  <div
    class="inplace-edit"
    @click="startEditing"
    v-if="!isEditing"
    :class="{ 'inplace-edit--disabled': disabled }"
  >
    <slot name="display">
      <span v-html="label || value || 'Click to edit'"></span>
    </slot>
  </div>
  <div v-else class="inplace-edit-form">
    <slot
      name="editor"
      :value="editValue"
      :update="updateValue"
      :save="saveChanges"
      :cancel="cancelEditing"
    >
      <!-- Default fallback to basic input/textarea -->
      <textarea
        v-if="multiline"
        v-model="editValue"
        @blur="saveChanges"
        @keydown.enter.prevent="saveChanges"
        @keydown.esc="cancelEditing"
        ref="input"
      ></textarea>
      <input
        v-else
        type="text"
        v-model="editValue"
        @blur="saveChanges"
        @keydown.enter="saveChanges"
        @keydown.esc="cancelEditing"
        ref="input"
      />
    </slot>
  </div>
</template>

<script>
  import { defineComponent, ref, watch } from 'vue';

  export default defineComponent({
    name: 'InplaceEdit',

    props: {
      value: {
        type: [String, Number, Array],
        default: '',
      },
      label: {
        type: String,
        default: null,
      },
      multiline: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    setup(props, { emit }) {
      const isEditing = ref(false);
      const editValue = ref(props.value);
      const input = ref(null);

      watch(
        () => props.value,
        (newValue) => {
          if (!isEditing.value) {
            editValue.value = newValue;
          }
        }
      );

      const startEditing = () => {
        isEditing.value = true;
        editValue.value = props.value;
        setTimeout(() => {
          input.value?.focus();
        });
      };

      const updateValue = (value) => {
        editValue.value = value;
      };

      const saveChanges = () => {
        isEditing.value = false;
        if (editValue.value !== props.value) {
          emit('update', editValue.value);
        }
      };

      const cancelEditing = () => {
        isEditing.value = false;
        editValue.value = props.value;
      };

      return {
        isEditing,
        editValue,
        input,
        startEditing,
        saveChanges,
        cancelEditing,
        updateValue,
      };
    },
  });
</script>

<style scoped lang="scss">
  .inplace-edit {
    padding: 0.5rem 0.5rem 0.5rem 0;
    cursor: pointer;

    &:hover {
      background: var(--slate-100);
      border-radius: 0.25rem;
    }
    &--disabled {
      cursor: default;
      opacity: 1;
      pointer-events: none;
    }
  }

  .inplace-edit-form {
    input,
    textarea {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid var(--slate-100);
      border-radius: 0.25rem;

      &:focus {
        outline: none;
        border-color: var(--primary-100);
      }
    }

    textarea {
      min-height: 100px;
      resize: vertical;
    }
  }
</style>
