<template>
  <div class="dropdown-menu" v-click-outside="closeDropdown">
    <div @click="toggleDropdown">
      <slot name="trigger"></slot>
    </div>
    <div class="dropdown-menu__content" v-if="isOpen">
      <slot name="items"></slot>
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue';

  export default {
    name: 'DropdownMenu',
    setup() {
      const isOpen = ref(false);

      const toggleDropdown = () => {
        isOpen.value = !isOpen.value;
      };

      const closeDropdown = () => {
        isOpen.value = false;
      };

      return {
        isOpen,
        toggleDropdown,
        closeDropdown,
      };
    },
  };
</script>

<style lang="scss">
  .dropdown-menu {
    position: relative;

    &__content {
      position: absolute;
      top: calc(100% + 0.5rem);
      right: 0;
      background-color: #ffffff;
      border: 1px solid var(--slate-200);
      border-radius: 0.5rem;
      padding: 0.5rem;
      min-width: 200px;
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
      z-index: 50;
      align-items: center;
      font-weight: 400;
      transform-origin: top right;
      animation: dropdown-open 0.2s ease-out forwards;

      &.closing {
        animation: dropdown-close 0.2s ease-in forwards;
      }
    }

    @keyframes dropdown-open {
      from {
        opacity: 0;
        transform: scale(0.95);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }

    @keyframes dropdown-close {
      from {
        opacity: 1;
        transform: scale(1);
      }
      to {
        opacity: 0;
        transform: scale(0.95);
      }
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem;
      width: 100%;
      text-align: left;
      color: var(--slate-500);
      border-radius: 0.25rem;
      font-weight: 500;
      cursor: pointer;

      @include hover {
        background-color: var(--slate-50);
      }

      &--danger {
        color: var(--red-600);

        @include hover {
          background-color: var(--red-50);
        }
      }
    }
  }
</style>
