<template>
  <div :class="iconClass" :style="iconStyle" v-html="iconContent"></div>
</template>

<script>
  import { defineComponent, ref, watch } from 'vue';

  export default defineComponent({
    name: 'BaseIcon',
    props: {
      name: {
        type: String,
        required: true,
      },
      size: {
        type: [String, Number],
        default: '16',
      },
      rotate: {
        type: [String, Number],
        default: 0,
      },
    },
    setup(props) {
      const iconContent = ref('');

      const loadIcon = async (iconName) => {
        try {
          const response = await fetch(`/icons/${iconName}.svg`);
          if (!response.ok) {
            throw new Error(`Failed to load icon: ${response.statusText}`);
          }
          const icon = await response.text();
          iconContent.value = icon;
        } catch (error) {
          console.error(`Failed to load icon ${iconName}:`, error);
          iconContent.value = '';
        }
      };

      watch(
        () => props.name,
        (newName) => {
          loadIcon(newName);
        },
        { immediate: true }
      );

      const iconClass = ref('icon');
      const iconStyle = ref({
        width: `${props.size}px`,
        height: `${props.size}px`,
        ...(props.rotate &&
          props.rotate !== 0 && {
            transform: `rotate(${props.rotate}deg)`,
          }),
      });

      return {
        iconContent,
        iconClass,
        iconStyle,
      };
    },
  });
</script>

<style lang="scss">
  .icon {
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    svg {
      display: inline-block;
      fill: currentColor;
      width: 100%;
      height: 100%;
    }
  }
</style>
