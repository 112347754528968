<template>
  <div class="submission-view">
    <div class="container">
      <PageHeader
        title="Submissions"
        description="View and manage form submissions in a table or pipeline view"
      />
      <div class="submission-view__charts">
        <div class="submission-view__chart">
          <Line :data="chartData" :options="chartOptions" />
        </div>
        <div class="submission-view__chart">
          <Bar :data="dropoffChartData" :options="dropoffChartOptions" />
        </div>
      </div>
      <div class="submission-view__controls" v-if="submissions && submissions.length > 0">
        <TableView
          :submissions="submissions"
          :loading="loading"
          @row-click="openSubmissionModal"
          :form="form"
          @export="exportSubmissions"
          :is-exporting="isExporting"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useFormStore } from '@/store/formStore';
  import { useUIStore } from '@/store/uiStore';
  import { formService } from '@/services/formService';

  import { formatDate } from '@/utils/date';
  import { Line } from 'vue-chartjs';
  import { Bar } from 'vue-chartjs';
  import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  import SubmissionDetails from '@/components/submissions/submissionDetails.vue';
  import TableView from '@/components/submissions/TableView.vue';
  import PageHeader from '@/components/partials/PageHeader.vue';

  export default {
    components: {
      TableView,
      PageHeader,
      Line,
      Bar,
    },
    setup() {
      const route = useRoute();
      const formStore = useFormStore();
      const uiStore = useUIStore();
      const formId = route.params.id;
      const form = computed(() => formStore.currentForm);

      // State
      const submissions = ref([]);
      const dailySubmissions = ref([]);
      const loading = ref(true);

      // Methods
      const loadSubmissions = async () => {
        loading.value = true;
        try {
          const response = await formStore.getFormSubmissions(formId);
          submissions.value = Array.isArray(response.submissions) ? response.submissions : [];
          dailySubmissions.value = response.dailySubmissionCounts;
        } catch (error) {
          console.error('Failed to load submissions:', error);
          submissions.value = [];
        } finally {
          loading.value = false;
        }
      };

      const openSubmissionModal = (submission) => {
        uiStore.addModal(SubmissionDetails, {
          title: 'Submission Details',
          submission,
          size: 'lg',
          form: formStore.currentForm,
        });
      };

      const chartData = computed(() => {
        // Get last 30 days
        const dates = Array.from({ length: 30 }, (_, i) => {
          const d = new Date();
          d.setDate(d.getDate() - i);
          return d.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
          });
        }).reverse();

        // Map the daily submission counts, defaulting to 0 if no data
        const counts = dates.map((date) => {
          const [month, day] = date.split('/');
          const year = new Date().getFullYear();
          const isoDate = `${year}-${month}-${day}`;
          return dailySubmissions.value?.[isoDate] || 0;
        });

        return {
          labels: dates,
          datasets: [
            {
              label: 'Daily submissions',
              data: counts,
              borderColor: '#3b82f6',
              backgroundColor: 'rgba(59, 130, 246, 0.2)', // Light blue background
              fill: 'origin',
              tension: 0.4, // Smooth curve
              borderWidth: 2,
            },
          ],
        };
      });

      const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Submissions over time',
            font: {
              size: 16,
              weight: '500',
            },
            padding: 20,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
          },
        },
        elements: {
          point: {
            radius: 3,
            hoverRadius: 6,
          },
        },
      };

      const calculateDropoffData = computed(() => {
        if (!submissions.value.length || !form.value)
          return { steps: [], counts: [], percentages: [] };

        const totalSubmissions = submissions.value.length;

        // Get number of pages from form
        const pages = form.value.pages;
        const numSteps = pages.length;

        // Initialize step counts object
        const stepCounts = {};
        for (let i = 1; i <= numSteps; i++) {
          stepCounts[i] = 0;
        }

        // Count submissions at each step
        submissions.value.forEach((submission) => {
          const currentStep = submission.metadata?.current_step;
          const isComplete = submission.is_complete;

          if (isComplete) {
            stepCounts[numSteps]++;
          } else if (currentStep && currentStep <= numSteps) {
            stepCounts[currentStep]++;
          }
        });

        // Calculate cumulative counts
        const cumulativeCounts = [];
        let remainingSubmissions = totalSubmissions;

        for (let i = 1; i <= numSteps; i++) {
          cumulativeCounts.push(remainingSubmissions);
          remainingSubmissions -= stepCounts[i];
        }

        // Calculate percentages
        const percentages = cumulativeCounts.map((count) =>
          Math.round((count / totalSubmissions) * 100)
        );

        // Generate step labels
        const steps = pages.map((page, index) => page.title || `Step ${index + 1}`);

        return {
          steps,
          counts: cumulativeCounts,
          percentages,
        };
      });

      const dropoffChartData = computed(() => ({
        labels: calculateDropoffData.value.steps,
        datasets: [
          {
            label: 'Completion Rate',
            data: calculateDropoffData.value.percentages,
            borderColor: '#3b82f6',
            backgroundColor: '#3b82f6',
            tension: 0.4,
          },
        ],
      }));

      const dropoffChartOptions = {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'top',
            labels: {
              generateLabels: (chart) => [
                {
                  text: `Completion rate (${calculateDropoffData.value.counts.join(' → ')} users)`,
                  fillStyle: '#3b82f6',
                  strokeStyle: '#3b82f6',
                  lineWidth: 0,
                  hidden: false,
                  index: 0,
                },
              ],
            },
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const count = calculateDropoffData.value.counts[context.dataIndex];
                return `${context.formattedValue}% (${count} users)`;
              },
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            ticks: {
              callback: (value) => `${value}%`,
            },
          },
        },
      };

      onMounted(loadSubmissions);

      const isExporting = ref(false);
      const exportSubmissions = async () => {
        isExporting.value = true;
        try {
          const response = await formService.exportSubmissions(formId);

          if (!response) throw new Error('Export failed');

          // Check if response is already a blob
          const blob =
            response instanceof Blob ? response : new Blob([response], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');

          a.href = url;
          a.download = `${form.value.name}-submissions-${formatDate(new Date())}.csv`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        } catch (error) {
          console.error('Failed to export submissions:', error);
        } finally {
          isExporting.value = false;
        }
      };

      return {
        submissions,
        loading,
        openSubmissionModal,
        chartData,
        chartOptions,
        form,
        dropoffChartData,
        dropoffChartOptions,
        exportSubmissions,
        isExporting,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/base/_mixins.scss';
  .submission-view {
    &__controls {
      margin-bottom: 20px;
    }
    &__charts {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      margin-bottom: 2rem;
      @include xl-down {
        grid-template-columns: 1fr;
      }
    }
    &__chart {
      min-height: 25vh;
      margin-bottom: 2rem;
      @include card('sm');
    }
    &__controls {
      margin-bottom: 20px;
    }
  }
</style>
