<template>
  <div class="create-edit-proposal">
    <form @submit.prevent="handleSubmit">
      <div class="form-row">
        <div class="form-section">
          <h2>Basic information</h2>
          <TextInput
            :id="inputs.title.id"
            :label="inputs.title.label"
            v-model="inputs.title.binding"
            :error="inputs.title.error"
            :rules="inputs.title.rules"
            :placeholder="inputs.title.placeholder"
            @validate="onValidate('title', $event)"
          />
          <div class="form-row">
            <DateInput
              :id="inputs.proposal_date.id"
              :label="inputs.proposal_date.label"
              v-model="inputs.proposal_date.binding"
              :error="inputs.proposal_date.error"
              :rules="inputs.proposal_date.rules"
              @validate="onValidate('proposal_date', $event)"
            />
            <NumberInput
              :id="inputs.days_valid_amount.id"
              :label="inputs.days_valid_amount.label"
              v-model="inputs.days_valid_amount.binding"
              :error="inputs.days_valid_amount.error"
              :rules="inputs.days_valid_amount.rules"
              @validate="onValidate('days_valid_amount', $event)"
            />
          </div>
        </div>

        <div class="form-section">
          <h2>Notes</h2>
          <TextAreaInput
            :id="inputs.client_notes.id"
            :label="inputs.client_notes.label"
            v-model="inputs.client_notes.binding"
            :error="inputs.client_notes.error"
            :rules="inputs.client_notes.rules"
            @validate="onValidate('client_notes', $event)"
          />
          <TextAreaInput
            :id="inputs.internal_notes.id"
            :label="inputs.internal_notes.label"
            v-model="inputs.internal_notes.binding"
            :error="inputs.internal_notes.error"
            :rules="inputs.internal_notes.rules"
            @validate="onValidate('internal_notes', $event)"
          />
        </div>
      </div>

      <div class="form-section">
        <h2>Items</h2>
        <div class="items-list">
          <div class="items-list__list" :class="{ 'items-list--error': items.error }">
            <div
              v-for="(item, index) in items.binding"
              :key="item.id"
              class="item-row"
              :class="{ 'item-row--error': item.error }"
            >
              <div class="item-row__content">
                <TextInput
                  :id="`item-${index}-description`"
                  label="Description"
                  v-model="item.description"
                  :error="item.errorType === 'description' ? item.error : ''"
                />
                <NumberInput
                  :id="`item-${index}-quantity`"
                  label="Quantity"
                  v-model="item.quantity"
                  min="1"
                  :error="item.errorType === 'quantity' ? item.error : ''"
                />
                <CurrencyInput
                  :id="`item-${index}-unit_price`"
                  label="Unit Price"
                  v-model="item.unit_price"
                  :error="item.errorType === 'unit_price' ? item.error : ''"
                  :rules="item.unit_price.rules"
                  @validate="onValidate('unit_price', $event)"
                />
                <SelectInput
                  :id="`item-${index}-vat_rate`"
                  label="VAT Rate"
                  v-model="item.vat_rate"
                  :options="vatRates"
                />
                <div class="item-totals">
                  <label>Total</label>
                  <div class="item-total">{{ formatCurrency(calculateItemTotal(item)) }}</div>
                </div>
              </div>
              <div class="item-row__actions">
                <Btn
                  type="button"
                  text="Clone"
                  @click="cloneItem(index)"
                  variant="plain"
                  icon="clone"
                  size="sm"
                  class="mr-4"
                />
                <Btn
                  type="button"
                  text="Remove"
                  @click="removeItem(index)"
                  variant="plain"
                  size="sm"
                />
              </div>
              <div v-if="item.error && !item.errorType" class="item-row__error">
                {{ item.error }}
              </div>
            </div>
          </div>
          <Btn variant="outlined" @click.prevent="addItem" icon="plus-circle" class="btn-add">
            Add item
          </Btn>
          <div class="items-list__error" v-if="items.error">{{ items.error }}</div>
        </div>
      </div>

      <div class="form-section totals">
        <h2>Summary</h2>
        <div class="summary-grid">
          <div>Subtotal: {{ formatCurrency(calculateSubtotal) }}</div>
          <div>Total VAT: {{ formatCurrency(calculateTotalVAT) }}</div>
          <div>Total: {{ formatCurrency(calculateFinalTotal) }}</div>
        </div>
      </div>
      <MessageElement :message="'Save your proposal first before sending'" />
      <div class="form-actions">
        <LoadingSpinner v-if="isLoadingProposal" />
        <Btn
          type="button"
          text="Save"
          :loading="isLoading || isSending"
          @click.prevent="handleSubmit('draft')"
          variant="secondary"
        />
        <Btn
          text="Review and send"
          @click.prevent="handleReviewAndSend"
          :loading="isSending"
          :disabled="!currentProposalId"
          :variant="!currentProposalId ? 'tertiary' : 'primary'"
        />
      </div>
    </form>
  </div>
</template>

<script>
  import { ref, computed, onMounted } from 'vue';
  import { v4 as uuidv4 } from 'uuid';
  import dayjs from 'dayjs';
  import TextInput from '@/components/inputs/TextInput.vue';
  import TextAreaInput from '@/components/inputs/TextAreaInput.vue';
  import NumberInput from '@/components/inputs/NumberInput.vue';
  import DateInput from '@/components/inputs/DateInput.vue';
  import CurrencyInput from '@/components/inputs/CurrencyInput.vue';
  import SelectInput from '@/components/inputs/SelectInput.vue';
  import Btn from '@/components/partials/Btn.vue';
  import { useValidation } from '@/composables/useValidation';
  import { crmService } from '@/services/crmService';
  import MessageElement from '@/components/partials/Message.vue';
  import { useToastStore } from '@/store/toastStore';
  import SendProposal from '@/components/crm/SendProposal.vue';
  import { useUIStore } from '@/store/uiStore';
  import LoadingSpinner from '@/components/partials/LoadingSpinner.vue';

  export default {
    name: 'CreateEditProposal',
    components: {
      TextInput,
      TextAreaInput,
      NumberInput,
      DateInput,
      CurrencyInput,
      SelectInput,
      Btn,
      MessageElement,
      LoadingSpinner,
    },
    props: {
      proposalId: {
        type: String,
        required: false,
      },
      leadId: {
        type: String,
        required: true,
      },
      onAdd: {
        type: Function,
        required: true,
      },
      onEdit: {
        type: Function,
        required: false,
      },
      onClose: {
        type: Function,
        required: false,
      },
      proposal: {
        type: Object,
        required: false,
      },
    },

    setup(props) {
      const isLoading = ref(false);
      const isLoadingProposal = ref(true);
      const isEditing = computed(() => !!props.proposalId || !!currentProposalId.value);
      const isSending = ref(false);
      const currentProposalId = ref(props.proposalId);
      const toastStore = useToastStore();
      const uiStore = useUIStore();
      const localProposal = ref(props.proposal);
      const items = ref({
        binding: [],
        error: '',
      });

      const vatRates = [
        { value: 0, label: '0%' },
        { value: 9, label: '9%' },
        { value: 21, label: '21%' },
      ];

      // Validation setup
      const { inputs, validateForm, onValidate } = useValidation({
        title: {
          id: 'title',
          label: 'Proposal subject',
          binding: '',
          error: '',
          rules: ['required', 'maxLength:255'],
        },
        proposal_date: {
          id: 'proposal_date',
          label: 'Proposal date',
          binding: new Date().toISOString().split('T')[0],
          error: '',
          rules: ['required'],
        },
        days_valid_amount: {
          id: 'days_valid_amount',
          label: 'Valid for (days)',
          binding: 30,
          error: '',
          rules: ['required', 'min:1'],
        },
        client_notes: {
          id: 'client_notes',
          label: 'Client notes',
          binding: '',
          error: '',
          rules: ['maxLength:2000'],
        },
        internal_notes: {
          id: 'internal_notes',
          label: 'Internal notes',
          binding: '',
          error: '',
          rules: ['maxLength:2000'],
        },
      });

      // Item management
      const addItem = () => {
        items.value.binding.push({
          id: uuidv4(),
          description: '',
          quantity: 1,
          unit_price: 0,
          vat_rate: 21,
        });
      };

      const removeItem = (index) => {
        items.value.binding.splice(index, 1);
      };

      const cloneItem = (index) => {
        const item = items.value.binding[index];
        items.value.binding.push({ ...item, id: uuidv4() });
      };

      // Calculations
      const calculateItemSubtotal = (item) => {
        return item.quantity * item.unit_price;
      };

      const calculateItemVAT = (item) => {
        return calculateItemSubtotal(item) * (item.vat_rate / 100);
      };

      const calculateItemTotal = (item) => {
        return calculateItemSubtotal(item) + calculateItemVAT(item);
      };

      const calculateSubtotal = computed(() => {
        return items.value.binding.reduce((sum, item) => sum + calculateItemSubtotal(item), 0);
      });

      const calculateTotalVAT = computed(() => {
        return items.value.binding.reduce((sum, item) => sum + calculateItemVAT(item), 0);
      });

      const calculateFinalTotal = computed(() => {
        return calculateSubtotal.value + calculateTotalVAT.value;
      });

      const calculatedValidUntil = computed(() => {
        const proposalDate = dayjs(inputs.value.proposal_date.binding);
        return proposalDate.add(inputs.value.days_valid_amount.binding, 'day').toDate();
      });

      const validateItems = () => {
        let isValid = true;
        items.value.error = '';

        if (!items.value.binding.length) {
          items.value.error = 'At least one item is required';
          return false;
        }

        items.value.binding.forEach((item, index) => {
          item.error = '';
          if (!item.description.trim()) {
            item.error = `Description is required`;
            item.errorType = 'description';
            isValid = false;
          } else if (item.quantity < 1) {
            item.error = `Quantity must be at least 1`;
            item.errorType = 'quantity';
            isValid = false;
          } else if (item.unit_price <= 0) {
            item.error = `Unit price must be greater than 0`;
            item.errorType = 'unit_price';
            isValid = false;
          }
        });

        return isValid;
      };

      // Form submission
      const handleSubmit = async (status = 'draft') => {
        isLoading.value = true;

        const allFieldsValid = await validateForm();
        const itemsValid = validateItems();

        if (!allFieldsValid || !itemsValid) {
          isLoading.value = false;
          return;
        }

        if (allFieldsValid) {
          const formData = {
            ...Object.keys(inputs.value).reduce((acc, key) => {
              acc[key] = inputs.value[key].binding;
              return acc;
            }, {}),
            items: items.value.binding.map(({ error, errorType, ...item }) => item),
            status: status,
            valid_until: calculatedValidUntil.value.toISOString().split('T')[0],
          };

          try {
            let response;
            if (isEditing.value) {
              response = await crmService.updateProposal(
                props.leadId,
                currentProposalId.value,
                formData
              );
              localProposal.value = response;
              toastStore.addToast({
                message: 'Proposal updated successfully',
                type: 'success',
                duration: 3000,
              });
              props.onEdit(response);
            } else {
              response = await crmService.createProposal(props.leadId, formData);
              currentProposalId.value = response.id;
              localProposal.value = response;
              props.onAdd(response);
              toastStore.addToast({
                message: 'Proposal created successfully',
                type: 'success',
                duration: 3000,
              });
            }
          } catch (error) {
            console.error('Error saving proposal:', error);
            toastStore.addToast({
              message: 'There was an error saving the proposal',
              type: 'error',
              duration: 3000,
            });
          }
        }
        isLoading.value = false;
      };

      const handleReviewAndSend = async () => {
        try {
          isLoading.value = true;
          isSending.value = true;

          await handleSubmit('draft');

          if (currentProposalId.value) {
            console.log(localProposal.value);
            const reviewModalId = uiStore.addModal(SendProposal, {
              title: 'Send proposal',
              description: 'Review this proposal and send it',
              proposalId: currentProposalId.value,
              proposal: localProposal.value,
              size: 'xxl',
              onSend: (sentEmail) => {
                uiStore.closeModal(reviewModalId);
                props.onClose(sentEmail);
              },
            });
          }
        } catch (error) {
          console.error('Error preparing proposal for sending:', error);
          toastStore.addToast({
            message: 'Error preparing proposal for sending',
            type: 'error',
            duration: 3000,
          });
        } finally {
          isLoading.value = false;
          isSending.value = false;
        }
      };

      const formatDate = (date) => {
        return format(new Date(date), 'dd-MM-yyyy');
      };

      // Currency formatter
      const formatCurrency = (amount) => {
        return new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR',
        }).format(amount);
      };

      onMounted(async () => {
        if (props.proposalId) {
          const response = await crmService.getProposal(props.leadId, props.proposalId);
          localProposal.value = response;
          // Set form inputs
          inputs.value.title.binding = response.title;
          inputs.value.proposal_date.binding = response.proposal_date.split('T')[0];
          inputs.value.client_notes.binding = response.client_notes;
          inputs.value.internal_notes.binding = response.internal_notes;

          // Set items
          items.value.binding = response.items.map((item) => ({
            id: item.id,
            description: item.description,
            quantity: item.quantity,
            unit_price: item.unit_price,
            vat_rate: item.vat_rate,
          }));
          isLoadingProposal.value = false;
        } else {
          addItem();
          isLoadingProposal.value = false;
        }
      });

      return {
        inputs,
        items,
        vatRates,
        isLoading,
        isSending,
        isLoadingProposal,
        onValidate,
        handleSubmit,
        handleReviewAndSend,
        addItem,
        removeItem,
        cloneItem,
        calculateItemSubtotal,
        calculateItemVAT,
        calculateItemTotal,
        calculateSubtotal,
        calculateTotalVAT,
        calculateFinalTotal,
        formatCurrency,
        formatDate,
        currentProposalId,
      };
    },
  };
</script>

<style scoped lang="scss">
  .create-edit-proposal {
    .items-list {
      &__list {
        .item-row {
          padding: 1rem;
          border: 1px solid var(--slate-200);
          border-radius: 0.5rem;
          margin-bottom: 1rem;
          &__content {
            display: grid;
            grid-template-columns: 2fr 1fr 1fr 1fr auto;
            gap: 1rem;
            align-items: start;

            .form-group {
              padding: 0;
            }
            @include lg-down {
              grid-template-columns: 1fr;
            }
          }
          &__actions {
            display: flex;
            margin-top: 1rem;
            padding-top: 0.25rem;
            border-top: 1px solid var(--slate-200);
          }
          &--error {
            border-color: var(--red-500);
          }
          &__error {
            color: var(--red-500);
            font-size: 0.875rem;
            margin-top: 0.5rem;
          }
        }
      }
      &__error {
        color: var(--red-500);
        font-size: 0.875rem;
        margin-top: 0.5rem;
      }
    }

    .item-totals {
      font-size: 0.875rem;
      color: var(--slate-600);
      display: flex;
      flex-direction: column;
      height: 100%;
      .item-total {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include lg-down {
          justify-content: flex-start;
        }
      }
    }

    .totals {
      .summary-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        font-weight: 600;
        @include lg-down {
          grid-template-columns: 1fr;
          margin-top: 1rem;
        }
      }
    }

    .form-actions {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      margin-top: 2rem;
    }

    .btn-add {
      width: 100%;
      margin-top: 1rem;
    }

    .btn-remove {
      color: var(--red-500);
      &:hover {
        color: var(--red-600);
      }
    }
  }
</style>
