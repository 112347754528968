<template>
  <div class="organization-settings">
    <div class="container">
      <PageHeader title="Organization" description="Manage your organization settings" />
      <Tabs v-model="activeTab">
        <Tab name="general" title="General">
          <div class="settings-section">
            <h3>Organization Details</h3>
            <div class="form-row">
              <TextInput
                label="Organization Name"
                v-model="inputs.name.binding"
                @blur="handleBlur('name')"
                @update:modelValue="handleChange('name')"
              />
              <SelectInput
                label="Language"
                v-model="inputs.language.binding"
                :options="[
                  { value: 'en-US', label: 'English' },
                  { value: 'nl-NL', label: 'Dutch' },
                ]"
                @blur="handleBlur('language')"
                @update:modelValue="handleChange('language')"
              />
            </div>
            <h3 class="mt-6">Address Information</h3>
            <div class="form-grid">
              <div class="form-row">
                <TextInput
                  :label="inputs.street_address.label"
                  v-model="inputs.street_address.binding"
                  @blur="handleBlur('street_address')"
                  @update:modelValue="handleChange('street_address')"
                />
                <TextInput
                  label="City"
                  v-model="inputs.city.binding"
                  @blur="handleBlur('city')"
                  @update:modelValue="handleChange('city')"
                />
                <TextInput
                  :label="inputs.state.label"
                  v-model="inputs.state.binding"
                  @blur="handleBlur('state')"
                  @update:modelValue="handleChange('state')"
                />
                <TextInput
                  :label="inputs.postal_code.label"
                  v-model="inputs.postal_code.binding"
                  @blur="handleBlur('postal_code')"
                  @update:modelValue="handleChange('postal_code')"
                />
                <SelectInput
                  :label="inputs.country.label"
                  v-model="inputs.country.binding"
                  :options="[
                    { value: 'NL', label: 'Netherlands' },
                    { value: 'BE', label: 'Belgium' },
                    { value: 'FR', label: 'France' },
                    { value: 'DE', label: 'Germany' },
                    { value: 'IT', label: 'Italy' },
                    { value: 'ES', label: 'Spain' },
                    { value: 'GB', label: 'United Kingdom' },
                    { value: 'CA', label: 'Canada' },
                    { value: 'US', label: 'United States' },
                    { value: 'other', label: 'Other' },
                  ]"
                  @blur="handleBlur('country')"
                  @update:modelValue="handleChange('country')"
                />
                <TextInput
                  :label="inputs.phone.label"
                  v-model="inputs.phone.binding"
                  @blur="handleBlur('phone')"
                  @update:modelValue="handleChange('phone')"
                />
                <TextInput
                  :label="inputs.vat_number.label"
                  v-model="inputs.vat_number.binding"
                  @blur="handleBlur('vat_number')"
                  @update:modelValue="handleChange('vat_number')"
                />
                <TextInput
                  :label="inputs.registration_number.label"
                  v-model="inputs.registration_number.binding"
                  @blur="handleBlur('registration_number')"
                  @update:modelValue="handleChange('registration_number')"
                />
                <TextInput
                  :label="inputs.bank_account.label"
                  v-model="inputs.bank_account.binding"
                  @blur="handleBlur('bank_account')"
                  @update:modelValue="handleChange('bank_account')"
                />
              </div>
            </div>
          </div>
        </Tab>
        <Tab name="team" title="Team">
          <div v-if="teamMembers.length > 0" class="member-list">
            <h3>Active Members</h3>
            <div v-for="member in teamMembers" :key="member.id" class="member-item">
              <div class="member-info">
                <AvatarElement
                  :title="
                    member.user.first_name
                      ? member.user.first_name?.charAt(0) + member.user.last_name?.charAt(0)
                      : ''
                  "
                  :alt="member.user.first_name + ' ' + member.user.last_name"
                />
                <div>
                  <h4>
                    {{
                      member.user.first_name
                        ? member.user.first_name + ' ' + member.user.last_name
                        : member.email
                    }}
                  </h4>
                  <p>{{ member.user.email }}</p>
                </div>
              </div>
              <div class="member-role">{{ member.role }}</div>
            </div>
            <Btn
              text="Invite team member"
              @click="openInviteMemberModal"
              icon="plus"
              variant="secondary"
              class="mt-4"
            />
          </div>

          <div v-if="pendingInvites.length > 0" class="pending-invites">
            <h3>Pending Invites</h3>
            <div v-for="invite in pendingInvites" :key="invite.id" class="invite-item">
              <div class="invite-info">
                <p>{{ invite.email }}</p>
                <span class="invite-status">Pending</span>
              </div>
              <div class="invite-actions">
                <Btn
                  text="Cancel"
                  variant="danger"
                  size="sm"
                  @click="cancelInvite(invite.id)"
                  disabled
                />
              </div>
            </div>
          </div>
        </Tab>
        <Tab name="brand" title="Brand">
          <div class="settings-section">
            <h3>Brand Settings</h3>
            <div class="form-grid">
              <ColorPicker
                :label="inputs.primary_color.label"
                v-model="inputs.primary_color.binding"
                @blur="handleBlur('primary_color')"
                @update:modelValue="handleChange('primary_color')"
              />
              <ColorPicker
                :label="inputs.primary_accent_color.label"
                v-model="inputs.primary_accent_color.binding"
                @blur="handleBlur('primary_accent_color')"
                @update:modelValue="handleChange('primary_accent_color')"
              />
              <FileInput
                :label="inputs.logo.label"
                accept="image/*"
                v-model="inputs.logo.binding"
                :is-uploading="isLogoUploading"
                :upload-config="{
                  type: 'logo',
                  organizationId: organization.id,
                  context: 'organization',
                }"
                @update:modelValue="handleChange('logo')"
              />
              <FileInput
                :label="inputs.icon.label"
                accept="image/*"
                v-model="inputs.icon.binding"
                :is-uploading="isIconUploading"
                :upload-config="{
                  type: 'icon',
                  organizationId: organization.id,
                  context: 'organization',
                }"
                @update:modelValue="handleChange('icon')"
              />
            </div>
          </div>
        </Tab>
        <Tab name="crm" title="CRM">
          <div class="settings-section">
            <h3>Default CRM Settings</h3>
            <div class="form-grid">
              <TextInput
                :label="inputs.proposal_mail_subject.label"
                v-model="inputs.proposal_mail_subject.binding"
                @blur="handleBlur('proposal_mail_subject')"
                @update:modelValue="handleChange('proposal_mail_subject')"
              />
              <WysiwygEditor
                :label="inputs.proposal_mail_body.label"
                v-model="inputs.proposal_mail_body.binding"
                @blur="handleBlur('proposal_mail_body')"
                @update:modelValue="handleChange('proposal_mail_body')"
              />
              <WysiwygEditor
                :label="inputs.proposal_introduction.label"
                v-model="inputs.proposal_introduction.binding"
                @blur="handleBlur('proposal_introduction')"
                @update:modelValue="handleChange('proposal_introduction')"
              />
              <MessageElement>
                Available variables: [lead_first_name], [lead_last_name], [proposal_title],
                [organization_name]
              </MessageElement>
            </div>
          </div>
          <Divider />
          <div class="settings-section">
            <h3>Proposal reminders</h3>
            <div class="form-row form-row--3">
              <ToggleInput
                v-model="inputs.proposal_reminder_send.binding"
                label="Send reminder email"
                :id="inputs.proposal_reminder_send.id"
              />
              <NumberInput
                v-if="inputs.proposal_reminder_send.binding"
                label="Reminder days before expiration date"
                v-model="inputs.proposal_reminder_days_before.binding"
                :disabled="!inputs.proposal_reminder_send.binding"
              />
            </div>
          </div>
        </Tab>
      </Tabs>
      <div class="mt-6">
        <Btn
          text="Save changes"
          @click="saveChanges"
          :disabled="Object.keys(pendingChanges).length === 0"
          :loading="isSaving"
          v-if="activeTab !== 'team'"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed, onMounted } from 'vue';
  import PageHeader from '@/components/partials/PageHeader.vue';
  import Btn from '@/components/partials/Btn.vue';
  import InviteMemberModal from '@/pages/settings/InviteMemberModal.vue';
  import { useUIStore } from '@/store/uiStore';
  import { useUserStore } from '@/store/userStore';
  import { useValidation } from '@/composables/useValidation';
  import { useToastStore } from '@/store/toastStore';
  import OrganizationService from '@/services/organizationService';
  import AvatarElement from '@/components/partials/Avatar.vue';
  import MessageElement from '@/components/partials/Message.vue';
  import FileService from '@/services/fileService';
  import Divider from '@/components/partials/Divider.vue';

  import Tabs from '@/components/partials/Tabs/Tabs.vue';
  import Tab from '@/components/partials/Tabs/Tab.vue';
  // import ColorInput from '@/components/inputs/ColorInput.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import ToggleInput from '@/components/inputs/ToggleInput.vue';
  import NumberInput from '@/components/inputs/NumberInput.vue';
  import FileInput from '@/components/inputs/FileInput.vue';
  import ColorPicker from '@/components/inputs/ColorPicker.vue';
  import SelectInput from '@/components/inputs/SelectInput.vue';
  import TextAreaInput from '@/components/inputs/TextAreaInput.vue';
  import WysiwygEditor from '@/components/inputs/WysiwygEditor.vue';

  export default {
    name: 'SettingsOrganizationView',

    components: {
      PageHeader,
      Btn,
      AvatarElement,
      Tabs,
      Tab,
      TextInput,
      FileInput,
      SelectInput,
      ColorPicker,
      ToggleInput,
      NumberInput,
      TextAreaInput,
      WysiwygEditor,
      MessageElement,
      Divider,
    },

    setup() {
      const activeTab = ref('general');
      const userStore = useUserStore();
      const organization = computed(() => userStore.currentOrganization);
      const toastStore = useToastStore();
      const pendingChanges = ref({});
      const isSaving = ref(false);
      const isLogoUploading = ref(false);
      const { inputs, validateForm, onValidate } = useValidation({
        // General Settings
        name: {
          id: 'name',
          label: 'Organization name',
          binding: organization.value.name,
          error: '',
          rules: ['required'],
        },
        language: {
          id: 'language',
          label: 'Language',
          binding: organization.value.language || 'en-US',
          error: '',
          rules: ['required'],
        },
        street_address: {
          id: 'street_address',
          label: 'Street address',
          binding: organization.value.address.street_address,
          error: '',
          rules: [],
        },
        city: {
          id: 'city',
          label: 'City',
          binding: organization.value.address.city,
          error: '',
          rules: [],
        },
        state: {
          id: 'state',
          label: 'State/Province',
          binding: organization.value.address.state,
          error: '',
          rules: [],
        },
        postal_code: {
          id: 'postal_code',
          label: 'Postal code',
          binding: organization.value.address.postal_code,
          error: '',
          rules: [],
        },
        country: {
          id: 'country',
          label: 'Country',
          binding: organization.value.address.country || 'NL',
          error: '',
          rules: [],
        },
        phone: {
          id: 'phone',
          label: 'Phone',
          binding: organization.value.address.phone,
          error: '',
          rules: [],
        },
        vat_number: {
          id: 'vat_number',
          label: 'VAT number',
          binding: organization.value.address.vat_number,
          error: '',
          rules: [],
        },
        registration_number: {
          id: 'registration_number',
          label: 'Registration number',
          binding: organization.value.address.registration_number,
          error: '',
          rules: [],
        },
        bank_account: {
          id: 'bank_account',
          label: 'Bank account number',
          binding: organization.value.address.bank_account,
          error: '',
          rules: [],
        },

        // Brand Settings
        primary_color: {
          id: 'primary_color',
          label: 'Primary Color',
          binding: organization.value.brand_settings.primary_color,
          error: '',
          rules: ['required'],
        },
        primary_accent_color: {
          id: 'primary_accent_color',
          label: 'Primary accent color',
          binding: organization.value.brand_settings.primary_accent_color,
          error: '',
          rules: ['required'],
        },
        logo: {
          id: 'logo',
          label: 'Logo',
          binding: organization.value.brand_settings.logo,
          error: '',
          rules: ['required'],
        },
        icon: {
          id: 'icon',
          label: 'Icon',
          binding: organization.value.brand_settings.icon,
          error: '',
          rules: [],
        },

        // CRM Settings
        proposal_mail_subject: {
          id: 'proposal_mail_subject',
          label: 'Default proposal mail subject',
          binding: organization.value.crm_settings.proposal_mail_subject,
          error: '',
          rules: ['required'],
        },
        proposal_mail_body: {
          id: 'proposal_mail_body',
          label: 'Default proposal mail body',
          binding: organization.value.crm_settings.proposal_mail_body,
          error: '',
          rules: ['required'],
        },
        proposal_introduction: {
          id: 'proposal_introduction',
          label: 'Default proposal introduction',
          binding: organization.value.crm_settings.proposal_introduction,
          error: '',
          rules: ['required'],
        },
        proposal_reminder_send: {
          id: 'proposal_reminder_send',
          label: 'Send reminder email',
          binding: organization.value.crm_settings.proposal_reminder_send || false,
          error: '',
          rules: ['required'],
        },
        proposal_reminder_days_before: {
          id: 'proposal_reminder_days_before',
          label: 'Reminder days before expiration date',
          binding: organization.value.crm_settings.proposal_reminder_days_before || 7,
          error: '',
          rules: ['required'],
        },
      });

      const trackChange = (fieldName) => {
        // Safety check
        if (!inputs.value[fieldName]) {
          console.warn(`Field ${fieldName} not found in inputs`);
          return;
        }

        const newValue = inputs.value[fieldName].binding;
        // Track the change but don't save immediately
        if (
          [
            'street_address',
            'city',
            'state',
            'postal_code',
            'country',
            'phone',
            'vat_number',
            'registration_number',
          ].includes(fieldName)
        ) {
          pendingChanges.value.address = {
            ...(pendingChanges.value.address || {}),
            [fieldName]: newValue,
          };
        } else if (['primary_color', 'primary_accent_color', 'logo', 'icon'].includes(fieldName)) {
          pendingChanges.value.brand_settings = {
            ...(pendingChanges.value.brand_settings || {}),
            [fieldName]: newValue,
          };
        } else if (
          ['proposal_mail_subject', 'proposal_mail_body', 'proposal_introduction'].includes(
            fieldName
          )
        ) {
          pendingChanges.value.crm_settings = {
            ...(pendingChanges.value.crm_settings || {}),
            [fieldName]: newValue,
          };
        } else {
          pendingChanges.value[fieldName] = newValue;
        }
      };

      const saveChanges = async () => {
        isSaving.value = true;
        if (Object.keys(pendingChanges.value).length === 0) return;
        try {
          await userStore.updateOrganization(organization.value.id, pendingChanges.value);
          toastStore.addToast({
            message: 'Settings updated successfully',
            type: 'success',
          });
          // Clear pending changes after successful save
          pendingChanges.value = {};
        } catch (error) {
          console.error('Failed to update organization:', error);
          toastStore.addToast({
            message: 'Failed to update settings',
            type: 'error',
          });
        } finally {
          isSaving.value = false;
        }
      };

      // Use this for blur events (immediate save)
      const handleBlur = (fieldName) => {
        trackChange(fieldName);
      };

      // Use this for change events (debounced save)
      const handleChange = (fieldName) => {
        trackChange(fieldName);
      };

      const handleLogoUpload = async (file, type = 'logo') => {
        if (!file) return;
        isLogoUploading.value = true;
        try {
          const response = await FileService.uploadFile(file, {
            type: type,
            organizationId: organization.value.id,
            context: 'organization',
          });

          if (response?.url) {
            pendingChanges.value.brand_settings = {
              ...(pendingChanges.value.brand_settings || {}),
              type: response.url,
            };
            inputs.value[type].binding = response.url;
          }
        } catch (error) {
          console.error('Failed to upload logo:', error);
          toastStore.addToast({
            message: 'Failed to upload logo',
            type: 'error',
          });
        } finally {
          isLogoUploading.value = false;
        }
      };

      const organizationService = new OrganizationService();
      const uiStore = useUIStore();

      const teamMembers = ref([]);
      const pendingInvites = ref([]);

      const openInviteMemberModal = () => {
        const modalId = uiStore.addModal(InviteMemberModal, {
          title: 'Invite team member',
          description: 'Invite a new team member to your organization',
          size: 'sm',
          organization: organization.value,
          onSubmit: (userData) => {
            toastStore.addToast({
              message: 'User invited successfully',
              type: 'success',
            });
            uiStore.closeModal(modalId);
          },
          onError: (error) => {
            console.error(error);
          },
        });
      };

      const fetchTeamData = async () => {
        try {
          const members = await organizationService.getMembers(organization.value.id);
          const invites = await organizationService.getInvites(organization.value.id);
          teamMembers.value = members.data;
          pendingInvites.value = invites.data;
        } catch (error) {
          console.error('Failed to fetch team data:', error);
        }
      };

      onMounted(() => {
        fetchTeamData();
      });

      return {
        organization,
        openInviteMemberModal,
        teamMembers,
        pendingInvites,
        activeTab,
        inputs,
        handleBlur,
        handleChange,
        pendingChanges,
        saveChanges,
        isSaving,
        handleLogoUpload,
      };
    },
  };
</script>

<style scoped lang="scss">
  .organization-settings {
    &__members {
      margin-top: 2rem;
      border-top: 1px solid var(--slate-200);
    }
  }
  .team-section {
    margin-top: 2rem;
  }

  .member-list,
  .pending-invites {
    background: white;
    border-radius: 8px;
    padding: 1.5rem 0;
    margin-bottom: 2rem;
  }

  .member-item,
  .invite-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: none;
    }
  }

  .member-info {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .member-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  .invite-status {
    font-size: 0.875rem;
    color: #666;
  }

  .invite-actions {
    display: flex;
    gap: 0.5rem;
  }
</style>
