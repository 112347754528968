<template>
  <div class="sf-el formula-input">
    <div class="sf-formula">
      <label class="sf-el__label">
        {{ label }}
        <span v-if="required" class="sf-el__required">*</span>
      </label>
      <span class="sf-formula__value">{{ formattedValue }}</span>
    </div>
  </div>
</template>

<script>
  import { watch, ref } from 'vue';
  export default {
    name: 'FormulaElement',
    props: {
      label: String,
      placeholder: String,
      name: String,
      modelValue: String,
      required: Boolean,
      display_as_currency: Boolean,
    },
    setup(props, { emit }) {
      const formattedValue = ref('');

      watch(
        () => props.modelValue,
        (newValue) => {
          if (props.display_as_currency && newValue) {
            // Convert to number and format as currency
            const numValue = Number(newValue);
            formattedValue.value = new Intl.NumberFormat('nl-NL', {
              style: 'currency',
              currency: 'EUR',
            }).format(numValue);
          } else {
            formattedValue.value = newValue;
          }
        },
        { immediate: true }
      );

      return {
        formattedValue,
      };
    },
  };
</script>

<style scoped lang="scss">
  .sf-formula {
    &__value {
      opacity: 0.8;
      font-size: 1.2rem;
    }
  }
</style>
