<template>
  <div class="page-sidebar" :class="{ 'page-sidebar--expanded': isEditorFocused }">
    <Tabs v-model="activeTab">
      <Tab name="elements" title="Elements">
        <SidebarElements />
      </Tab>
      <!-- <Tab name="pages" title="Pages">
        <SidebarPages
          :form="form"
          :activePageIndex="activePageIndex"
          @add-page="addPage"
          @remove-page="removePage"
          @edit-page-settings="editPageSettings"
          @set-active-page="setActivePage"
        />
      </Tab> -->
      <Tab name="design" title="Design">
        <SidebarDesign @editor-focus="handleEditorFocus" />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
  import { ref } from 'vue';
  // import { useFormStore } from '@/store/formStore';
  // import { useUIStore } from '@/store/uiStore';

  import Tabs from '@/components/partials/Tabs/Tabs.vue';
  import Tab from '@/components/partials/Tabs/Tab.vue';

  import SidebarElements from '@/components/editor/sidebar/general/SidebarElements.vue';
  import SidebarPages from '@/components/editor/sidebar/general/SidebarPages.vue';
  import SidebarDesign from '@/components/editor/sidebar/general/SidebarDesign.vue';

  export default {
    components: {
      SidebarPages,
      Tabs,
      Tab,
      SidebarDesign,
      SidebarElements,
    },
    props: {
      form: {
        type: Object,
      },
      activePageIndex: {
        type: Number,
      },
    },
    emits: ['add-page', 'remove-page', 'edit-page-settings', 'set-active-page'],
    setup(props, { emit }) {
      const activeTab = ref('elements');

      const addPage = () => emit('add-page');
      const removePage = (index) => emit('remove-page', index);
      const editPageSettings = (index) => emit('edit-page-settings', index);
      const setActivePage = (index) => emit('set-active-page', index);

      const isEditorFocused = ref(false);

      const handleEditorFocus = (focused) => {
        isEditorFocused.value = focused;
      };

      return {
        activeTab,
        addPage,
        removePage,
        editPageSettings,
        setActivePage,
        isEditorFocused,
        handleEditorFocus,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/base/mixins';
  .page-sidebar {
    width: 230px;
    padding: 1rem;
    background: var(--white);
    height: 100%;
    overflow-y: auto;
    transition: width 100ms ease-in-out;
    &--expanded {
      width: 450px;
    }
    .pages {
      .page-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0.75rem;
        @include hover {
          background-color: var(--slate-100);
          border-radius: 0.5rem;
        }
        &__actions {
          display: flex;
        }
        &__action {
          &--drag {
            cursor: move; /* fallback if grab cursor is unsupported */
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;
            &:active {
              cursor: grabbing;
              cursor: -moz-grabbing;
              cursor: -webkit-grabbing;
            }
          }
        }
      }
    }
  }
</style>
