<template>
  <div class="login-page">
    <div class="login-page__main">
      <div class="login-page__wrap">
        <h2 class="login-page__title">Welcome back!</h2>
        <p class="mt-2 mb-8">
          Don't have an account?
          <router-link to="/register">Create today!</router-link>
        </p>
        <form @submit.prevent="login" v-if="useAuth" class="login-page__form">
          <TextInput
            :id="inputs.email.id"
            :label="inputs.email.label"
            v-model="inputs.email.binding"
            type="email"
          />
          <TextInput
            :id="inputs.password.id"
            :label="inputs.password.label"
            v-model="inputs.password.binding"
            type="password"
          />
          <div @click="resetPassword()" class="login-page__forgot-password">Forgot password?</div>
          <Btn
            type="submit"
            :loading="isLoading"
            @click="login"
            variant="secondary"
            icon="user"
            class="width-100 mt-2"
            text="Sign in"
          />
        </form>
        <Divider />
        <GoogleAuthButton
          mode="auth"
          @connected="handleGoogleAuthSuccess"
          @error="handleGoogleAuthError"
          :loading="isGoogleAuthLoading"
          class="width-100"
        />
      </div>
    </div>
    <div class="login-page__aside">
      <div class="login-page__aside__content">
        <img src="@/assets/img/auth-visual@2x.png" alt="FormFlow" />
      </div>
    </div>
  </div>
</template>

<script>
  import { useRouter } from 'vue-router';
  import { ref } from 'vue';
  import { supabase } from '@/services/supabase';
  import { useUserStore } from '@/store/userStore';
  import { useUIStore } from '@/store/uiStore';

  import GoogleAuthButton from '@/components/inputs/GoogleAuthButton.vue';
  import TextInput from '@/components/inputs/TextInput.vue';
  import Btn from '@/components/partials/Btn.vue';
  import Divider from '@/components/partials/Divider.vue';

  export default {
    components: {
      TextInput,
      Btn,
      GoogleAuthButton,
      Divider,
    },
    setup() {
      const router = useRouter();
      const userStore = useUserStore();
      const uiStore = useUIStore();
      const useAuth = import.meta.env.VITE_USE_AUTH === 'true';
      const isLoading = ref(false);
      const isGoogleAuthLoading = ref(false);

      // Using a composable for validation
      const inputs = ref({
        email: {
          id: 'email',
          label: 'Email address',
          binding: '',
          error: '',
        },
        password: {
          id: 'password',
          label: 'Password',
          binding: '',
          error: '',
        },
      });

      const login = async () => {
        isLoading.value = true;
        try {
          await userStore.login(inputs.value.email.binding, inputs.value.password.binding);
          router.push('/all-flows');
        } catch (error) {
          isLoading.value = false;
          uiStore.addModal(null, {
            title: 'Something went wrong',
            description: 'Cannot login. Please try again.',
          });
        }
      };

      const resetPassword = async () => {
        try {
          if (!inputs.value.email.binding) {
            uiStore.addModal(null, {
              title: 'Email Required',
              description: 'Please enter your email address to reset your password.',
            });
            return;
          }

          const { error } = await supabase.auth.resetPasswordForEmail(inputs.value.email.binding, {
            redirectTo: `${window.location.origin}/reset-password`,
          });

          if (error) {
            if (error.message.includes('rate limit')) {
              uiStore.addModal(null, {
                title: 'Too many attempts',
                description: 'Please wait a few minutes before trying again.',
              });
            } else {
              throw error;
            }
            return;
          }

          uiStore.addModal(null, {
            title: 'Password reset link sent',
            description: 'Please check your email to reset your password.',
          });
        } catch (error) {
          uiStore.addModal(null, {
            title: 'Something went wrong',
            description: 'Cannot reset password. Please try again.',
          });
        }
      };

      const navigateToFormBuilder = () => {
        router.push('/form-builder');
      };

      const handleGoogleAuthSuccess = async (data) => {
        isGoogleAuthLoading.value = true;
        try {
          console.log('Google auth initiated');
          // await userStore.fetchUserData();
          // router.push('/all-flows');
        } catch (error) {
          console.error('Error handling auth success:', error);
        }
      };

      const handleGoogleAuthError = (error) => {
        console.error('Google auth error:', error);
        alert(error.message);
      };

      return {
        inputs,
        isLoading,
        login,
        resetPassword,
        useAuth,
        navigateToFormBuilder,
        handleGoogleAuthSuccess,
        handleGoogleAuthError,
        isGoogleAuthLoading,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/base/mixins';
  .login-page {
    display: flex;
    min-height: 100vh;
    @include lg-down {
      flex-direction: column;
    }
    &__main {
      width: 50%;
      align-items: center;
      justify-content: center;
      display: flex;
      min-height: 100%;
      background-color: #ffffff;
      padding: 4rem 0;
      p a {
        text-decoration: underline;
      }
      @include lg-down {
        width: 100%;
        min-height: 100vh;
        padding: 1rem;
      }
    }
    &__wrap {
      @include lg-down {
        width: 100%;
      }
    }
    &__form {
      max-width: 100%;
      width: 400px;
      @include lg-down {
        width: 100%;
      }
    }
    &__aside {
      width: 50%;
      min-height: 100%;
      background-color: #092220;
      background: linear-gradient(49.61deg, #092220 46.86%, #1b4743 101.41%);
      padding: 8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        max-width: 400px;
      }
      @include lg-down {
        width: 100%;
      }
    }
    &__forgot-password {
      margin-bottom: 1rem;
      text-align: right;
      cursor: pointer;
    }
  }
</style>
