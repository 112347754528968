<template>
  <div class="publish-funnel-form">
    <div class="publish-funnel-form__link">
      <label>Share with your form link</label>
      <div class="publish-funnel-form__copy">
        <input
          type="text"
          readonly
          :value="form.published_at ? formLink : ''"
          :placeholder="!form.published_at ? 'Publish your form to get a shareable link' : ''"
          ref="linkInput"
        />
        <Btn
          icon="copy"
          text="Copy"
          @click.prevent="copyLink"
          variant="secondary"
          :disabled="!form.published_at"
        />
      </div>
    </div>
    <div class="publish-funnel-form__placement" v-if="form.published_at">
      <label>Or embed as...</label>
      <div class="publish-funnel-form__options">
        <div class="publish-funnel-form__option" @click="selectPlacement('sidebar')">
          <img
            src="@/assets/img/sidebar.png"
            alt="Sidebar placement icon"
            class="publish-funnel-form__option-img"
          />

          <span>Sidebar</span>
          <Btn class="publish-funnel-form__option-btn" variant="secondary">Reveal embed code</Btn>
        </div>
        <div class="publish-funnel-form__option" @click="selectPlacement('modal')">
          <img
            src="@/assets/img/modal.png"
            alt="Modal placement icon"
            class="publish-funnel-form__option-img"
          />
          <span>Modal</span>
          <Btn class="publish-funnel-form__option-btn" variant="secondary">Reveal embed code</Btn>
        </div>
        <div class="publish-funnel-form__option" @click="selectPlacement('inline')">
          <img
            src="@/assets/img/inline.png"
            alt="Inline placement icon"
            class="publish-funnel-form__option-img"
          />
          <span>Inline</span>
          <Btn class="publish-funnel-form__option-btn" variant="secondary">Reveal embed code</Btn>
        </div>
      </div>
    </div>

    <div class="publish-funnel-form__buttons">
      <Btn
        icon="zap"
        type="submit"
        :disabled="formStore.isPublishing"
        :loading="formStore.isPublishing"
        :variant="form.published_at ? 'tertiary' : 'secondary'"
        @click="publishForm"
        class="publish-btn"
        :class="{ 'publish-btn--published': form.published_at }"
      >
        <div class="publish-btn__dot"></div>
        {{ form.published_at ? 'Unpublish' : 'Publish' }}
      </Btn>
      <!-- <Btn
        icon="zap"
        @click="publishForm"
        :variant="canPublish ? 'secondary' : 'tertiary'"
        :disabled="!canPublish"
        class="publish-btn"
        :class="{ 'publish-btn--published': form.published_at }"
      >
        <div class="publish-btn__dot"></div>
        {{ publishButtonText }}
      </Btn> -->
    </div>
  </div>
</template>

<script>
  import { ref, computed } from 'vue';
  import { useRoute } from 'vue-router';
  import Btn from '@/components/partials/Btn.vue';
  import BaseIcon from '@shared/components/BaseIcon.vue';
  import FormPreview from '@/pages/public/FormPreview.vue';
  import { useConfetti } from '@/services/confettiService';

  import { useFormStore } from '@/store/formStore';
  import { useToastStore } from '@/store/toastStore';
  import { useUIStore } from '@/store/uiStore';
  export default {
    components: {
      Btn,
      BaseIcon,
      FormPreview,
    },
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      onError: {
        type: Function,
        required: true,
      },
    },
    setup(props) {
      const route = useRoute();
      const formStore = useFormStore();
      const toastStore = useToastStore();
      const uiStore = useUIStore();
      const form = computed(() => formStore.currentForm);

      const isLoading = ref(false);
      const copied = ref(false);
      const linkInput = ref(null);

      const formLink = computed(() => {
        return `${import.meta.env.VITE_FORMS_URL}/${route.params.id}`;
      });

      const copyLink = () => {
        linkInput.value.select();
        document.execCommand('copy');
        copied.value = true;
        toastStore.addToast({
          message: 'Link copied!',
        });
        setTimeout(() => {
          copied.value = false;
        }, 2000);
      };

      const selectPlacement = (placement) => {
        const modalId = uiStore.addModal(FormPreview, {
          title: 'Flow preview',
          description: 'Here you can see your flow in the selected placement',
          size: 'xxl',
          formId: form.value.id,
          placement: placement,
        });
      };

      const confetti = useConfetti();
      const publishForm = async () => {
        try {
          if (form.value.published_at) {
            const unpublishedForm = await formStore.unpublishForm(form.value.id);
            form.value = unpublishedForm;
          } else {
            confetti.fire();
            const publishedForm = await formStore.publishChanges(form.value.id);
            form.value = publishedForm;
          }
        } catch (error) {
          console.log(error);
          // props.onError(error);
        }
      };

      return {
        form,
        isLoading,
        copied,
        linkInput,
        formLink,
        copyLink,
        publishForm,
        selectPlacement,
        formStore,
      };
    },
  };
</script>

<style scoped lang="scss">
  .publish-funnel-form {
    &__placement {
      margin-bottom: 2rem;

      label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: 500;
      }
    }

    &__options {
      display: flex;
      gap: 1rem;
    }

    &__option {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      padding: 1rem;
      border: 1px solid var(--slate-200);
      border-radius: 0.5rem;
      cursor: pointer;
      transition: all 0.2s;
      background-color: var(--slate-100);
      position: relative;

      &:hover {
        border-color: var(--slate-300);
        .publish-funnel-form__option-btn {
          opacity: 1;
        }
        .publish-funnel-form__option-img,
        span {
          opacity: 0.8;
        }
      }

      &.active {
        border-color: var(--brand-500);
        background-color: var(--slate-50);
      }

      span {
        font-size: 0.875rem;
      }
      &--disabled {
        cursor: default;
        opacity: 0.5;
      }
    }

    &__option-thumbnail {
      width: 100%;
      height: auto;
      position: relative;
    }

    &__option-btn {
      position: absolute;
      margin: 0 auto;
      top: 50%;
      transform: translateY(-50%);
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      opacity: 0;
      transition: opacity 0.2s;
      z-index: 10;
    }

    &__option-img {
      width: 100%;
      height: auto;
    }

    &__link {
      margin-bottom: 2rem;

      label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: 500;
      }
    }

    &__copy {
      display: flex;
      gap: 0.5rem;

      input {
        flex: 1;
        padding: 0.5rem;
        border: 1px solid var(--slate-200);
        border-radius: 0.375rem;
        font-size: 0.875rem;
      }
    }

    &__buttons {
      display: flex;
    }
    .publish-btn {
      --color: var(--slate-200);
      &--published {
        --color: var(--green-500);
      }
      &__dot {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: var(--color);
        margin-right: 0.5rem;
        flex-shrink: 0;
      }
    }
  }
</style>
