import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { plugin as VueTippy } from 'vue-tippy';
import { clickOutside } from './directives/click-outside';

import 'tippy.js/dist/tippy.css';

import './config/axiosConfig';
import './assets/scss/main.scss';

// Create the app instance
const app = createApp(App);

// Add global error handler
app.config.errorHandler = (err, instance, info) => {
  console.error('Global error:', err);
  console.error('Error info:', info);
};

window.onerror = function (message, source, lineno, colno, error) {
  console.error('Window error:', { message, source, lineno, colno, error });
};

// Create a fresh Pinia instance
const pinia = createPinia();

// Install plugins
app.use(pinia);
app.use(router);
app.config.devtools = import.meta.env.VITE_NODE_ENV === 'development';
app.directive('click-outside', clickOutside);
app.use(
  VueTippy,
  // optional
  {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>
    componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
    defaultProps: {
      placement: 'auto-end',
      allowHTML: true,
    },
  }
);

// Initialize the app
const init = async () => {
  try {
    app.mount('#app');
  } catch (error) {
    const body = document.querySelector('body');
    body.innerHTML = `<h1>Error: ${error.message}</h1>`;
    console.error('Failed to initialize app:', error);
  }
};

init();
