import { ref, computed } from 'vue';

// This detects whether we're in a Nuxt environment or not
const isNuxt = typeof process !== 'undefined' && process.server !== undefined;

// Add debug logging
const debug = (...args: any[]) => {
  console.log('[useI18n Debug]', ...args);
};

export function useI18n() {
  debug('Initializing useI18n');
  debug('isNuxt:', isNuxt);

  // In a real setup, this would be imported from your main app's i18n instance
  // But for demonstration, we'll create a simple implementation

  if (isNuxt) {
    // For Nuxt, use nuxt-i18n
    debug('Nuxt environment detected, trying to use nuxt-i18n');
    const nuxtApp = getNuxtApp();
    debug('nuxtApp:', nuxtApp);
    const i18n = nuxtApp?.$i18n;
    debug('i18n from nuxtApp:', i18n);

    if (i18n) {
      debug('Using Nuxt i18n implementation');
      return {
        t: i18n.t,
        locale: computed(() => i18n.locale.value),
        setLocale: (newLocale: string) => i18n.setLocale(newLocale),
      };
    }
    debug('Nuxt i18n not available, falling back to custom implementation');
  }

  // Fallback or Vite implementation
  debug('Using fallback implementation');

  // Map browser locales to our available translation files
  const mapLocale = (browserLocale: string): string => {
    // Extract the language part (e.g., 'en' from 'en-GB')
    const language = browserLocale.split('-')[0].toLowerCase();
    debug('Mapping browser locale:', browserLocale, 'language part:', language);

    // Map to our available translations
    if (language === 'nl') return 'nl-NL';
    // Default to English
    return 'en-EN';
  };

  const browserLocale = typeof navigator !== 'undefined' ? navigator?.language : 'en';
  debug('Browser locale:', browserLocale);

  const initialLocale = mapLocale(browserLocale || 'en') || 'en-EN';
  debug('Initial locale mapped to:', initialLocale);

  const locale = ref(initialLocale);
  const isLoaded = ref(false); // Add loading state
  let messages: Record<string, Record<string, string>> = {};

  // Available translations
  const availableTranslations = ['en-EN', 'nl-NL'];
  debug('Available translations:', availableTranslations);

  // Dynamic import of translation files - requires a bundler that supports dynamic imports
  const loadTranslations = async (loc: string) => {
    debug('Loading translations for:', loc);
    // Make sure we're loading a translation that exists
    const translationKey = availableTranslations.includes(loc) ? loc : 'en-EN';
    debug('Translation key to load:', translationKey);

    try {
      // We'll try to import the translation file dynamically
      debug('Attempting to import:', `../translations/${translationKey}.ts`);
      const translationModule = await import(`../translations/${translationKey}.ts`);
      debug('Translation module loaded:', translationModule);
      messages[translationKey] = translationModule.default;
      debug('Messages updated for', translationKey, messages[translationKey]);

      // If we had to use a fallback, update the locale ref
      if (translationKey !== loc) {
        console.warn(`Translation for ${loc} not found, using ${translationKey} instead`);
        locale.value = translationKey;
        debug('Updated locale to fallback:', locale.value);
      }
    } catch (error) {
      console.error(`Failed to load translations for ${translationKey}`, error);
      debug('Translation load error:', error);
      // If we somehow failed to load even the fallback English translation,
      // we should provide an empty object to prevent further errors
      messages[translationKey] = {};
      debug('Set empty messages for', translationKey);
    }
  };

  // Translation function
  const t = (key: string) => {
    debug('Translating key:', key, 'with locale:', locale.value);

    // Return a placeholder while translations are loading
    if (!isLoaded.value || !messages[locale.value]) {
      debug('Translations not yet loaded, returning key:', key);
      return key;
    }

    const keys = key.split('.');
    let result: any = messages[locale.value];
    debug('Initial result object:', result);

    for (const k of keys) {
      if (result && typeof result === 'object') {
        result = result[k];
        debug('Traversing key:', k, 'result:', result);
      } else {
        debug('Key not found, returning original key:', key);
        return key;
      }
    }

    debug('Final translation result:', result);
    return typeof result === 'string' ? result : key;
  };

  // Initialize with the current locale
  debug('Initializing translations with locale:', locale.value);
  loadTranslations(locale.value).then(() => {
    isLoaded.value = true;
    debug('Initial translations loaded');
  });

  // Set a new locale and load its translations
  const setLocale = async (newLocale: string) => {
    debug('Setting new locale:', newLocale);
    // Only try to load translations if the locale is one we support
    // or map it to one we do support
    const mappedLocale = availableTranslations.includes(newLocale)
      ? newLocale
      : mapLocale(newLocale);
    debug('Mapped locale:', mappedLocale);

    if (!messages[mappedLocale]) {
      debug('Translations not loaded yet for', mappedLocale, 'loading now...');
      await loadTranslations(mappedLocale);
    } else {
      debug('Translations already loaded for', mappedLocale);
    }
    locale.value = mappedLocale;
    debug('Locale updated to:', locale.value);
  };

  const result = {
    t,
    locale: computed(() => locale.value),
    setLocale,
    availableLocales: computed(() => availableTranslations),
    isLoaded: computed(() => isLoaded.value), // Expose loading state
  };

  debug('Returning i18n interface:', result);
  return result;
}

// Type-safe utility for Nuxt detection
function getNuxtApp() {
  debug('Getting Nuxt app');
  try {
    // @ts-ignore - This will be available in Nuxt environment
    const app = typeof useNuxtApp === 'function' ? useNuxtApp() : undefined;
    debug('Nuxt app result:', app);
    return app;
  } catch (error) {
    debug('Error getting Nuxt app:', error);
    return undefined;
  }
}
