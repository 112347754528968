<template>
  <div class="sf-el address address-input">
    <div class="sf-address">
      <label class="sf-el__label">
        {{ label }}
        <span v-if="required" class="sf-el__required">*</span>
      </label>

      <div class="address__fields">
        <div class="address__field-row address__field-row--no-break">
          <div class="address__field address__field--street">
            <input
              type="text"
              v-model="addressData.street"
              :placeholder="t('form.fields.address.street')"
              class="address__input"
            />
          </div>
          <div class="address__field address__field--number">
            <input
              type="text"
              v-model="addressData.houseNumber"
              :placeholder="t('form.fields.address.house_number')"
              class="address__input"
            />
          </div>
        </div>
        <div class="address__field-row">
          <div class="address__field">
            <input
              type="text"
              v-model="addressData.postalCode"
              :placeholder="t('form.fields.address.postal_code')"
              class="address__input"
            />
          </div>
          <div class="address__field">
            <input
              type="text"
              v-model="addressData.city"
              :placeholder="t('form.fields.address.city')"
              class="address__input"
            />
          </div>

          <div class="address__field">
            <select
              v-model="addressData.country"
              class="address__input"
              placeholder="Select country"
            >
              <option value="" class="placeholder" disabled>
                {{ t('form.fields.address.select_country') }}
              </option>
              <option v-for="country in countries" :key="country.value" :value="country.value">
                {{ country.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { watch, ref } from 'vue';
  import { COUNTRIES } from '@shared/constants/countries';
  import { useI18n } from '@shared/composables/useI18n';

  export default {
    name: 'AddressElement',
    props: {
      label: String,
      description: String,
      name: String,
      modelValue: {
        type: Object,
        default: () => ({
          houseNumber: '',
          street: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        }),
      },
      required: Boolean,
    },
    emits: ['update:modelValue'],
    async setup(props, { emit }) {
      const { t, isLoaded } = useI18n();
      const addressData = ref({ ...props.modelValue });

      if (!isLoaded.value) {
        await new Promise((resolve) => {
          watch(isLoaded, (newValue) => {
            if (newValue) resolve();
          });
        });
      }

      if (!addressData.value.country) {
        addressData.value.country = '';
      }

      watch(
        addressData,
        (newValue) => {
          emit('update:modelValue', newValue);
        },
        { deep: true }
      );

      return {
        addressData,
        countries: COUNTRIES,
        t,
      };
    },
  };
</script>

<style scoped lang="scss">
  .address {
    &__fields {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__field {
      width: 100%;

      &--number {
        width: 120px;
        flex-shrink: 0;
      }

      &--postal {
        width: 120px;
        flex-shrink: 0;
      }
    }

    &__field-row {
      display: flex;
      gap: 16px;
      @include lg-down {
        flex-direction: column;
      }
      &--no-break {
        @include lg-down {
          flex-direction: row;
        }
      }
    }
  }
</style>
