<template>
  <div class="form-pages">
    <draggable
      v-model="form.pages"
      @end="onDragEnd"
      item-key="id"
      :animation="200"
      class="pages-container"
    >
      <template #item="{ element, index }">
        <div
          :key="element.id"
          class="page-item"
          :class="{ ['page-item--active']: activePageIndex === index }"
          @click="setActivePage(index)"
        >
          <span class="page-item__title">{{ element.title }}</span>
          <div class="page-item__actions">
            <Btn
              @click="editPageSettings(index)"
              variant="plain"
              icon="edit"
              class="page-item__action"
            />
          </div>
        </div>
      </template>
    </draggable>
    <Btn text="Add page" @click="addPage" variant="secondary" class="add-page-btn" />
  </div>
</template>

<script>
  import { computed } from 'vue';
  import { useFormStore } from '@/store/formStore';
  import { useUIStore } from '@/store/uiStore';
  import draggable from 'vuedraggable';
  import Btn from '@/components/partials/Btn.vue';
  import PageSettingsModalForm from '@/components/editor/settings/PageSettingsModalForm.vue';

  export default {
    name: 'FormPages',
    components: {
      Btn,
      draggable,
    },
    props: {
      activePageIndex: {
        type: Number,
      },
    },
    emits: ['addPage', 'removePage', 'editPageSettings', 'setActivePage'],
    setup(props, { emit }) {
      const formStore = useFormStore();
      const uiStore = useUIStore();

      const form = computed(() => formStore.currentForm);

      const addPage = async () => {
        const response = await formStore.addPage();
        if (response.status === 'success') {
          if (form.value.pages.length === 1) {
            formStore.setActivePage(0);
          }
        } else {
          console.error('Failed to add page:', response.error);
          alert('Failed to add page');
        }
      };

      const editPageSettings = (index) => {
        const modalId = uiStore.addModal(PageSettingsModalForm, {
          title: 'Edit the page settings',
          pageIndex: index,
          onSubmit: async () => {
            uiStore.closeModal(modalId);
          },
          onError: (errors) => {
            console.error('Form submission errors:', errors);
          },
        });
      };

      const setActivePage = (index) => {
        formStore.setActivePage(index);
      };

      const onDragEnd = async () => {
        await formStore.saveCurrentForm();
      };

      return {
        form,
        addPage,
        editPageSettings,
        onDragEnd,
        setActivePage,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/base/mixins';

  .form-pages {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid var(--slate-200);

    .pages-container {
      display: flex;
      flex-grow: 1;
      gap: 0.25rem;
      overflow-x: auto;
      padding: 0.5rem;
    }

    .page-item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.3125rem 0.5rem;
      border-radius: 0.5rem;
      border: 1px solid var(--slate-200);
      background: var(--white);
      color: var(--slate-500);
      white-space: nowrap;
      @include fontSize('sm');
      @include hover {
        cursor: pointer;
        background-color: var(--slate-50);
      }

      &--active {
        background-color: var(--slate-100);
        border-color: var(--slate-500);
        color: var(--slate-900);
      }

      &__title {
        font-size: 0.875rem;
      }

      &__actions {
        display: flex;
        gap: 0.25rem;
      }

      &__action {
        &--drag {
          cursor: move;
          cursor: grab;
          cursor: -moz-grab;
          cursor: -webkit-grab;

          &:active {
            cursor: grabbing;
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
          }
        }
      }
    }

    .add-page-btn {
      margin-left: 0.5rem;
      flex-shrink: 0;
    }
  }
</style>
