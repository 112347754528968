<template>
  <div class="toolbar">
    <div class="container">
      <div class="toolbar__inner">
        <nav class="toolbar__nav">
          <div class="toolbar__organization">
            <div class="company-switcher" @click="toggleOrganizationSwitcher">
              <div class="company-switcher__header">
                <div class="company-switcher__logo">
                  <AvatarElement
                    :backgroundImage="currentOrganization.brand_settings?.icon"
                    v-bind="
                      !currentOrganization.brand_settings?.icon && {
                        title: currentOrganization.name
                          ? currentOrganization.name.substring(0, 2).toUpperCase()
                          : '',
                        color: currentOrganization.brand_settings?.primary_color,
                        textColor: currentOrganization.brand_settings?.primary_accent_color,
                      }
                    "
                  />
                </div>
                <div class="company-switcher__name">{{ currentOrganization.name }}</div>
                <div class="company-switcher__chevrons">
                  <BaseIcon name="chevron" size="10" />
                  <BaseIcon name="chevron" size="10" rotate="180" />
                </div>
              </div>
            </div>
          </div>
          <ul
            class="toolbar__nav-list toolbar__nav-list--main"
            v-if="type === 'default'"
            :class="{ 'toolbar__nav-list--open': isMobileMenuOpen }"
          >
            <li>
              <router-link to="/all-flows">
                <BaseIcon name="flows" size="14" />
                All funnels
              </router-link>
            </li>
            <li v-if="isAdmin" class="toolbar__link toolbar__link--automations">
              <router-link to="/automations">
                <BaseIcon name="wand" size="14" />
                Automations
              </router-link>
            </li>
            <li>
              <router-link to="/insights">
                <BaseIcon name="insights" size="14" />
                Insights
              </router-link>
            </li>
            <li>
              <router-link to="/crm">
                <BaseIcon name="crm" size="14" />
                CRM
              </router-link>
            </li>
          </ul>
        </nav>
        <nav class="toolbar__nav">
          <ul class="toolbar__nav-list toolbar__nav-list--account">
            <li class="toolbar__account">
              <div class="account-dropdown" @click="toggleAccountMenu">
                <AvatarElement
                  :title="userAvatar ? null : userInitial"
                  :backgroundImage="userAvatar"
                />
                <BaseIcon name="chevron" size="12" rotate="180" />
                <div class="account-dropdown__menu" v-if="showAccountMenu">
                  <router-link to="/settings" class="account-dropdown__item">Settings</router-link>
                  <div class="account-dropdown__item" @click="logOut">Sign out</div>
                </div>
              </div>
            </li>
          </ul>
          <button class="toolbar__hamburger lg-down" @click="toggleMobileMenu">
            <BaseIcon name="hamburger" size="20" />
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { useUserStore } from '@/store/userStore';
  import AvatarElement from '@/components/partials/Avatar.vue';
  import BaseIcon from '@shared/components/BaseIcon.vue';
  import { useUIStore } from '@/store/uiStore';
  import OrganizationSwitcherModal from '@/components/organizationSwitch/OrganizationSwitcherModal.vue';

  import { useRoleCheck } from '@/composables/useRoleCheck';
  export default {
    name: 'ToolbarNav',
    components: {
      AvatarElement,
      BaseIcon,
      OrganizationSwitcherModal,
    },
    props: {
      type: {
        type: String,
        default: 'default',
      },
    },
    setup() {
      const userStore = useUserStore();
      const router = useRouter();
      const { isAdmin, isMember } = useRoleCheck();
      const currentOrganization = computed(() => userStore.currentOrganization);
      const currentUser = computed(() => userStore.user);
      const userInitial = computed(() => {
        if (userStore.user && userStore.user.user_metadata.first_name) {
          return (
            userStore.user.user_metadata.first_name.charAt(0) +
            userStore.user.user_metadata.last_name.charAt(0)
          );
        }
        return '';
      });

      const isMobileMenuOpen = ref(false);

      const userAvatar = computed(() => userStore.user.user_metadata.avatar_url);

      const uiStore = useUIStore();
      function toggleOrganizationSwitcher() {
        const modalId = uiStore.addModal(OrganizationSwitcherModal, {
          title: 'Choose your organization',
          description: 'Choose which organization you would like to switch to',
          size: 'sm',
          onSubmit: async (data) => {
            uiStore.closeModal(modalId);
          },
          onError: (errors) => {
            console.error('Form submission errors:', errors);
          },
        });
      }

      async function logOut() {
        try {
          await userStore.logout();
          router.push('/login');
        } catch (error) {
          console.error('Error signing out: ', error);
        }
      }

      const showAccountMenu = ref(false);

      function toggleAccountMenu() {
        showAccountMenu.value = !showAccountMenu.value;
      }

      function toggleMobileMenu() {
        isMobileMenuOpen.value = !isMobileMenuOpen.value;
      }

      onMounted(() => {
        document.addEventListener('click', (e) => {
          if (!e.target.closest('.account-dropdown')) {
            showAccountMenu.value = false;
          }
        });
        document.addEventListener('click', (e) => {
          if (
            !e.target.closest('.toolbar__nav-list--main') &&
            !e.target.closest('.toolbar__hamburger')
          ) {
            isMobileMenuOpen.value = false;
          }
        });
      });
      return {
        showAccountMenu,
        toggleAccountMenu,
        userInitial,
        logOut,
        currentOrganization,
        currentUser,
        toggleOrganizationSwitcher,
        userAvatar,
        isAdmin,
        isMember,
        isMobileMenuOpen,
        toggleMobileMenu,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .toolbar {
    width: 100%;
    position: relative;
    height: 80px;
    background-color: #ffffff;
    border-bottom: 1px solid var(--slate-200);
    @include lg-down {
      height: 58px;
    }
    &__nav {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    &__nav-list {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      li {
        a {
          padding: 6px 8px;
          border-radius: 0.5rem;
          border: 1px solid transparent;
          color: var(--slate-500);
          display: flex;
          align-items: center;
          gap: 0.5rem;
          // @include fontSize('sm');
          @include hover {
            cursor: pointer;
            background-color: var(--slate-50);
          }
          &.router-link-active {
            background-color: var(--slate-100);
            border-color: var(--slate-200);
            color: var(--slate-900);
          }
        }
        @include fontSize('md');
        font-weight: 500;
        &.toolbar__link--automations {
          @include lg-down {
            display: none;
          }
        }
      }
      &--main {
        @include lg-down {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          background-color: #ffffff;
          border-top: 1px solid var(--slate-200);
          padding: 0.5rem 0;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.5rem;
          z-index: 1000;
          padding: 0.5rem 1rem;
          li {
            width: 100%;
          }
          display: none;
        }
      }
      &--open {
        display: flex;
      }
    }
    &__hamburger {
      display: none;
      background: none;
      border: none;
      padding: 0.5rem;
      color: var(--slate-500);
      cursor: pointer;
      @include lg-down {
        display: flex;
      }
      @include hover {
        color: var(--slate-700);
      }
    }
    &__inner {
      padding: 1rem 0;
      display: flex;
      justify-content: space-between;
      @include lg-down {
        padding: 0.25rem 0;
      }
    }
  }
  .company-switcher {
    display: flex;
    align-items: center;
    font-weight: 600;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid transparent;
    margin-right: 3rem;
    @include hover {
      border-color: var(--slate-200);
      cursor: pointer;
      background-color: var(--slate-50);
      .company-switcher__chevrons {
        color: var(--slate-500);
      }
    }
    &__name {
      @include lg-down {
        display: none;
      }
    }
    &__header {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    &__chevrons {
      display: flex;
      flex-direction: column;
      color: var(--slate-300);
      .icon {
        &:last-child {
          // transform: rotate(180deg);
        }
      }
    }
  }
  .account-dropdown {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 600;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid transparent;
    gap: 0.5rem;
    .icon {
      color: var(--slate-500);
      // transform: rotate(180deg);
    }
    @include hover {
      border-color: var(--slate-200);
      cursor: pointer;
      background-color: var(--slate-50);
      .company-switcher__chevrons {
        color: var(--slate-500);
      }
    }

    &__menu {
      position: absolute;
      top: calc(100% + 0.5rem);
      right: 0;
      background-color: #ffffff;
      border: 1px solid var(--slate-200);
      border-radius: 0.5rem;
      padding: 0.5rem;
      min-width: 200px;
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem;
      width: 100%;
      text-align: left;
      color: var(--slate-500);
      border-radius: 0.25rem;
      font-weight: 500;
      @include hover {
        background-color: var(--slate-50);
      }
    }
  }
</style>
