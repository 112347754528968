<template>
  <div class="form-element">
    <component
      :is="getElementComponent(element.type)"
      v-bind="getElementProps(element)"
      @update:modelValue="(value) => handleInput(element, value)"
      @blur="handleBlur(element.id)"
      :formId="formId"
      :fieldId="element.id"
      :key="element.id"
    />
    <div class="form-element__error" v-if="errors">
      {{ errors }}
    </div>
  </div>
</template>

<script>
  import AddressElement from './elements/AddressElement.vue';
  import EmailElement from './elements/EmailElement.vue';
  import SingleChoiceElement from './elements/SingleChoiceElement.vue';
  import MultiChoiceElement from './elements/MultiChoiceElement.vue';
  import PictureChoiceElement from './elements/PictureChoiceElement.vue';
  import ShortAnswerElement from './elements/ShortAnswerElement.vue';
  import LongAnswerElement from './elements/LongAnswerElement.vue';
  import NameElement from './elements/NameElement.vue';
  import PhoneElement from './elements/PhoneElement.vue';
  import NumberElement from './elements/NumberElement.vue';
  import SliderElement from './elements/SliderElement.vue';
  import HeadingElement from './elements/HeadingElement.vue';
  import TextElement from './elements/TextElement.vue';
  import SchedulingElement from './elements/SchedulingElement.vue';
  import FormulaElement from './elements/FormulaElement.vue';
  import SwitchElement from './elements/SwitchElement.vue';

  export default {
    name: 'FormElement',

    components: {
      AddressElement,
      EmailElement,
      SingleChoiceElement,
      MultiChoiceElement,
      PictureChoiceElement,
      ShortAnswerElement,
      LongAnswerElement,
      NameElement,
      PhoneElement,
      NumberElement,
      SliderElement,
      HeadingElement,
      TextElement,
      SchedulingElement,
      FormulaElement,
      SwitchElement,
    },

    props: {
      element: {
        type: Object,
        required: true,
      },
      formData: {
        type: Object,
        required: true,
      },
      formId: {
        type: String,
        required: true,
      },
      errors: {
        type: Array,
        default: null,
      },
      touched: {
        type: Object,
        default: () => ({}),
      },
      dummy: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['update:formData', 'field-blur'],

    setup(props, { emit }) {
      const getElementComponent = (type) => {
        const components = {
          address: AddressElement,
          email: EmailElement,
          'single-choice': SingleChoiceElement,
          'multiple-choice': MultiChoiceElement,
          'picture-choice': PictureChoiceElement,
          name: NameElement,
          number: NumberElement,
          phone: PhoneElement,
          'short-answer': ShortAnswerElement,
          'long-answer': LongAnswerElement,
          heading: HeadingElement,
          text: TextElement,
          scheduling: SchedulingElement,
          slider: SliderElement,
          switch: SwitchElement,
          formula: FormulaElement,
        };
        return components[type] || ShortAnswerElement;
      };

      const getElementProps = (element) => {
        const modelValue =
          element.type === 'switch'
            ? props.formData[element.id] === true || props.formData[element.id] === 'true'
            : props.formData[element.id];

        return {
          ...element.props,
          modelValue,
          error: props.errors ? props.errors[element.id] : null,
          touched: props.touched[element.id],
          formId: props.formId,
          fieldId: element.id,
        };
      };
      const handleInput = (element, value) => {
        emit('update:formData', {
          [element.id]: value,
        });
      };

      const handleBlur = (elementId) => {
        emit('field-blur', elementId);
      };

      return {
        getElementComponent,
        getElementProps,
        handleInput,
        handleBlur,
      };
    },
  };
</script>

<style scoped lang="scss">
  .form-element {
    margin-bottom: 0.5rem;
    padding: 0.5rem 0;

    &:last-child {
      margin-bottom: 0;
    }

    // Add error state styling if needed
    &--error {
      border-color: var(--error-500);
    }

    // Add touched state styling if needed
    &--touched {
      // Your styles here
    }

    &__error {
      color: var(--red-500);
      font-size: 0.875rem;
      margin-top: 0.75rem;
    }
  }
</style>
